
import baseUrl from "../../plugins/axios/axios.js";
const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  
  /**
   *get all payments
   */
  getAllPayments(_,params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/users/payments`,{params:params})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },
  /**
   *get all Histories
   */
  getAllHistories(_,params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`users/payments/histories`,{params:params})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
