<template>
  <section class="pagination">
    <ul>
      <i
        :class="`bx bx-chevrons-left ${prevIsDisable ? 'disable' : 'bx-fade-left'}`"
        @click="prev"
      ></i>

      <div v-if="this.pages.length <= 5">
        <li
          v-for="(item, i) in pages"
          :key="i"
          @click="changePage(item)"
          :class="item === currentPage ? 'active-page' : ''"
        >
          <p>{{ item }}</p>
        </li>
      </div>
      <div v-else>
        <li
          v-for="(item, i) in firstPages"
          :key="i"
          @click="changePage(item)"
          :class="item === currentPage ? 'active-page' : ''"
        >
          <p>{{ item }}</p>
        </li>
        <span v-if="midPages.length > 0">...</span>
        <li
          v-for="(item, i) in midPages"
          :key="i"
          @click="changePage(item)"
          :class="item === currentPage ? 'active-page' : ''"
        >
          <p>{{ item }}</p>
        </li>
        <span>...</span>
        <li
          v-for="(item, i) in lastPages"
          :key="i"
          @click="changePage(item)"
          :class="item === currentPage ? 'active-page' : ''"
        >
          <p>{{ item }}</p>
        </li>
      </div>
      <i
        :class="`bx bx-chevrons-right ${nextIsDisable ? 'disable' : 'bx-fade-right'}`"
        @click="next"
      ></i>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["total", "page", "lastPage", "loading"],
  data() {
    return {
      //currentPage & total & lastPage should come from props
      CurrentPage: 1,
      pages: [],
      firstPages: [],
      midPages: [],
      lastPages: [],
    };
  },
  computed: {
    prevIsDisable() {
      return this.page > 1 && this.page <= this.lastPage ? false : true;
    },
    nextIsDisable() {
      return this.page >= 1 && this.page < this.lastPage ? false : true;
    },
  },
  watch: {
    // loading(val) {
    //   if (val === false) {
    //     this.initialPages();
    //   }
    // },
  },
  created() {
    this.currentPage = this.page;
    this.initialPages();
  },
  methods: {
    initialPages() {
      this.pages = [];
      this.firstPages = [];
      this.midPages = [];
      this.lastPages = [];
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
      if (this.lastPage >= 5) {
        if (this.currentPage <= 3) {
          this.firstPages = this.pages.slice(0, 3);
          this.lastPages = this.pages.slice(this.pages.length - 2, this.pages.length);
        } else if (this.currentPage > 3 && this.currentPage < this.pages.length - 2) {
          this.firstPages.push(this.pages[0]);
          this.midPages.push(
            this.currentPage - 1,
            this.currentPage,
            this.currentPage + 1
          );
          this.lastPages.push(this.pages[this.pages.length - 1]);
        } else if (this.currentPage >= this.lastPage - 2) {
          this.firstPages = this.pages.slice(0, 2);
          this.lastPages = this.pages.slice(this.pages.length - 3, this.pages.length);
        }
      }
    },
    next() {
      if (this.currentPage >= 1 && this.currentPage < this.lastPage) this.currentPage++;
      this.initialPages();
      this.$emit("nextPage", this.currentPage);
    },
    prev() {
      if (this.currentPage > 1 && this.currentPage <= this.lastPage) this.currentPage--;
      this.initialPages();
      this.$emit("nextPage", this.currentPage);
    },
    changePage(item) {
      this.currentPage = item;
      this.initialPages();
      this.$emit("nextPage", this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.pagination {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-top: 20px;
  @include sm {
    transform: scale(0.8);
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    padding: 8px 0;
    border-radius: 30px;
    box-shadow: 0 5px 10px black;
    border: 2px solid white;
    // margin-top: 10px;
    i {
      font-size: 38px;
      cursor: pointer;
      color: var(--color5);
      margin: 0 10px;
      border-radius: 50%;
      &:hover {
        color: var(--color7);
        &.disable {
          opacity: 0.5 !important;
        }
      }
      &.disable {
        color: grey;
        opacity: 0.5;
        cursor: unset;
      }
    }
    div {
      display: flex;
      li {
        width: 35px;
        height: 35px;
        margin: 0 5px;
        background: var(--color6);
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: black;
        cursor: pointer;
        box-shadow: 0 10px 10px black;
        &.active-page {
          background: var(--color7);
          color: white;
        }
      }
    }
    span {
      display: flex;
      align-items: center;
    }
  }
}
</style>
