<template>
  <div class="top-scores">
    <section class="avatar">
      <img src="@/assets/images/running-card/card3.jpg" alt="" class="img" />
    </section>
    <section class="information" v-if="parsedTopScores">
      <h4><i class="bx bxs-user"></i> {{ parsedTopScores.user.username }}</h4>
      <h5><i class="bx bxs-envelope"></i> {{ parsedTopScores.user.email }}</h5>
      <h3><i class="bx bxs-trophy"></i>Score : {{ parsedTopScores.score }}</h3>
    </section>
    <section class="information" v-else>
      <h6>top scores</h6>
      <h4>tournament is running</h4>
      <p class="Q-font">When tournament finished data will available</p>
    </section>
  </div>
</template>

<script>
export default {
  props: ["topScores"],
  data() {
    return {};
  },
  computed: {
    parsedTopScores() {
      return JSON.parse(this.topScores);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-scores {
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    .img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 4px solid var(--color7);
      margin-right: 5px;
    }
  }
  .information {
    text-align: start;
    i {
      font-size: 20px;
      margin: 0 2px;
    }
    h4,
    h5,
    h3 {
      display: flex;
      align-items: center;
    }
    h4 {
      color: var(--color7);
    }
    h5 {
      color: var(--color1);
    }
    h3 {
      color: orange;
    }
  }
}
</style>
