<template>
  <div class="tooltip-container">
    <span class="tooltip Q-font">{{text}}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props:['text']
};
</script>

<style lang="scss">
@import "@/assets/style/_mixins.scss";
.tooltip-container {
  height: fit-content;
  width: fit-content;
  position: relative;
  // background: red;
  display: initial;
  &:hover {
    .tooltip {
      display: block;
    }
  }
  .tooltip {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    white-space: nowrap;
    padding: 6px 8px;
    background: black;
    border-radius: 5px;
    font-size: 14px;
    font-style: italic;
    @include md{
      font-size: 10px;
      top: -40px;
    }
    @include sm{
      font-size: 8px;
      top: -35px;
    }
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -8px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid black;
    }
  }
}
</style>
