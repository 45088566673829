const state = {
    alerts: [],
};

const getters = {
    // Get Alerts
    alerts: (state) => state.alerts,
};

const mutations = {

    // Set Alert
    setAlert(state, alert) {
        state.alerts.push(alert);
    },

    // Clear Alerts
    clearAlerts(state) {
        state.alerts = [];
    },
};

const actions = {
    // Set Alert
    setAlert({ commit }, alert) {
        commit("setAlert", alert);

        setTimeout(() => {
            commit("clearAlerts");
        }, 4000);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};