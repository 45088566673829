<template>
  <h1 class="page-title observe-item">{{ title }} <span>{{background}}</span></h1>
</template>

<script>
export default {
  props: ["title","background"],
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.page-title {
  color: white !important;
  font-size: 2.3rem;
  font-weight: 600;
  line-height: 47.84px;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @include md {
      font-size: 1.8rem;
      }
  @include sm {
      font-size: 1.2rem;
      margin: 0;
      }
  span {
    content: var(--title);
    position: absolute;
    opacity: 0.08;
    font-size: 100px;
    font-weight: 900;
    // transform: translate(-22%,-15%);
   @include md{
      font-size: 55px;
      }
   @include sm{
      font-size: 35px;
      }
  }
}
</style>
