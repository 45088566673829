<template>
  <div>
    <div class="overlay" @click="toggleSidebar"></div>
    <section class="sidebar">
      <div class="header">
        <div class="avatar-wrapper">
          <div class="avatar">
            <img
              :src="avatarImage"
              width="70%"
              height="70%"
              alt=""
              class="avatar-img"
              @click="$router.push({ name: 'profile' })"
            />
            <span class="online-flag"></span>
            <h3 class="user-name">{{ user.name }}</h3>
            <!-- <router-link :to="{name:'profile'}" class="user-name Q-font"
            >Edit Profile</router-link
          > -->
          </div>
        </div>
        <hr class="line" />
      </div>
      <div class="main">
        <ul class="items">
          <SidebarItem
            v-for="(item, i) in items"
            :key="i"
            :item="item"
            @close="toggleSidebar"
          ></SidebarItem>
        </ul>
      </div>
      <section class="sidebar-open-btn">
        <button @click="toggleSidebar"></button>
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem.vue";
import { swipe } from "../../../mixins/swipe.js";
export default {
  mixins: [swipe],
  data() {
    return {
      // isOpen: false,
      items: [
        { text: "Dashboard", name: "dashboard", icon: "dashboard" },
        { text: "Profile", name: "profile", icon: "user" },
        { text: "Games", name: "games", icon: "dice-5" },
        { text: "In-App Purchases", name: "purchases", icon: "purchase-tag" },
        { text: "Tournaments", name: "tournaments", icon: "trophy" },
        { text: "Wallets", name: "wallets", icon: "wallet" },
        { text: "Payments", name: "payments", icon: "credit-card" },
        { text: "Tickets", name: "tickets", icon: "conversation" },
      ],
      sidebar: null,
      overlay: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    avatarImage() {
      return this.user.avatar
        ? this.storageImageLink
        : require("@/assets/images/profile/avatar.png");
    },
    storageImageLink() {
      if (this.user.avatar.includes("http")) return this.user.avatar;
      else return `${process.env.VUE_APP_ROOT_STORAGE}/${this.user.avatar}`;
    },
  },
  watch: {
    user(val) {},
  },
  mounted() {
    this.sidebar = document.querySelector(".sidebar");
    this.overlay = document.querySelector(".overlay");
    if (window.innerWidth > 850) this.toggleSidebarOnHover();
    window.addEventListener("resize", () => {
      if (window.innerWidth > 850) this.toggleSidebarOnHover();
    });

    // const home = document.querySelector(".home");
    // this.swipe(home, "right", this.openSidebar);
    // this.swipe(home, "left", this.closeSidebar);
  },

  components: {
    SidebarItem,
  },
  methods: {
    // openSidebar() {
    //   //for swipe right
    //   this.sidebar.classList.add("open");
    //   this.overlay.classList.add("open");
    // },
    // closeSidebar() {
    //   //for swipe left
    //   this.sidebar.classList.remove("open");
    //   this.overlay.classList.remove("open");
    // },
    toggleSidebarOnHover() {
      //for desktop screen
      this.sidebar.addEventListener("mouseover", () => {
        this.sidebar.classList.add("open");
      });
      this.sidebar.addEventListener("mouseout", () => {
        this.sidebar.classList.remove("open");
      });
    },
    toggleSidebar() {
      //for mobile screen
      this.sidebar.classList.toggle("open");
      this.overlay.classList.toggle("open");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.407);
  display: none;
  &.open {
    display: unset;
  }
}
.sidebar {
  width: 70px;
  background: white;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 15px 13px;
  transition: all 0.5s ease;
  z-index: 1000;
  border-radius: 50px;
  cursor: pointer;
  @include md {
    width: 0;
    padding: 0;
  }
  // @media only screen and (min-width: 1625px){
  //   left: 2%;
  // }
  &.open {
    width: 290px;
    height: 100vh;
    padding: 15px 18px;
    box-shadow: 10px 0px 30px black;
    border-radius: 0;
    cursor: unset;
    .avatar {
      margin: 0;
      .online-flag {
        opacity: 1;
      }
    }

    .line {
      display: block;
    }
    .user-name {
      opacity: 1 !important;
      transform: scale(1) !important;
      z-index: 1 !important;
      margin: 0 !important;
    }
    .sidebar-open-btn {
      button {
        position: absolute;
        left: unset;
        right: 0;
        border-radius: 15px 0 0 15px;
      }
    }
  }
  .toggle-open {
    position: absolute;
    right: -10px;
    i {
      z-index: 1000000;
    }
  }
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    .avatar-img {
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid black;
      aspect-ratio: 1;
      min-width: 43px;
    }
    .online-flag {
      position: absolute;
      width: 16px;
      height: 16px;
      background: rgb(0, 188, 0);
      right: 22%;
      bottom: 20%;
      border: 2px solid white;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.5s ease;
    }
    .user-name {
      transform: scale(0);
      transition: all 0.5s ease;
      opacity: 1;
      margin-top: -20px;
      z-index: -50;
    }
  }
  .line {
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, black, transparent);
    margin: 5px 0;
    display: none;
  }
  .game-selected {
    display: flex;
    align-items: center;
    text-align: end;
    margin-left: 5px;
    .bx {
      font-size: 30px;
      margin-right: 60px;
    }
    a {
      font-weight: 400;
    }
  }
  .logout {
    position: absolute;
    bottom: 10px;
  }
  .sidebar-open-btn {
    button {
      position: fixed;
      left: 0;
      top: 48%;
      width: 15px;
      height: 70px;
      display: none;
      font-size: 35px;
      color: rgb(255, 249, 249);
      border-radius: 0 10px 10px 0;
      cursor: pointer;
      box-shadow: 5px 0 10px black;
      &::before {
        content: "";
        position: absolute;
        background: black;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20%;
        height: 40%;
        border-radius: 25px;
      }
      @include md {
        display: block;
      }
    }
  }
}
</style>
