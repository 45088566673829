
import baseUrl from "../../plugins/axios/axios.js";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  
  /**
   *get user wallets
   */
  getAllWallets() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/wallet")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *add Wallet
   */
   addWallet(_, info) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/wallet",info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *edit Wallet
   */
   editWallet(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .put(`/users/wallet/${payload.id}`, payload.info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *charge Wallet
   */
   chargeWallet(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/wallet/${payload.id}/payments/new`,payload.chargeData)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *withdraw request
   */
   withdrawRequest(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/wallet/${payload.walletId}/withdraws`,payload.withdrawData)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *transfer request
   */
   transferRequest(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/wallet/${payload.walletId}/transfer`,payload.transferData)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *get user withdraw requests
   */
   getAllWithdraws() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/users/wallet/withdraws`)
        .then((result) => {
         
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *get Coins list
   */
   getAllCoins() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/helper/coins")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *get pay methods list
   */
   getAllPayMethods() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/helper/gateways")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
