<template>
  <div class="switch-container">
    <div class="switch-holder">
      <div class="switch-label">
        <span :class="`${isChecked ? 'active' : ''}`">{{ label }}</span>
        <p class="message warning Q-font">{{ warningMessage }}</p>
        <p class="message success Q-font">{{ successMessage }}</p>
      </div>
      <div class="switch-toggle">
        <input
          type="checkbox"
          :id="id"
          :checked="isChecked"
          @change="updateValue($event)"
          ref="checkInput"
          :disabled="disabled"
        />
        <label :for="id">
          <span>{{ isChecked ? "ON" : "OFF" }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    id: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      required: false,
    },
    warningMessage: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    value() {
     
      this.isChecked = this.value;
    },
  },
  created() {
    this.isChecked = this.value;
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.checked); //this line so neccessery(v-model working on this component because of this line )
      this.isChecked = e.target.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.switch-holder {
  width: 100%;
  min-width: 220px;
  display: flex;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3), inset 10px 10px 10px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  align-items: center;
}

.switch-label {
  font-size: 12px;
  color: white;
  span {
    transition: all 0.3s ease-in-out;
    &.active {
      color: cyan;
      text-shadow: 0 0 5px cyan;
    }
  }
  .message {
    font-size: 9px;
    font-style: italic;
    &.warning {
      color: orange;
    }
    &.success {
      color: #2aef2a;
      text-shadow: 0 0 5px #2aef2a;
    }
  }
}

.switch-toggle {
  height: 40px;
  width: 50%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-toggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: 1;
}

.switch-toggle input[type="checkbox"] + label {
  position: relative;
  width: 100%;
  height: 90%;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.086),
    inset 10px 10px 10px rgba(0, 0, 0, 0.25);
  user-select: none;
}

.switch-toggle input[type="checkbox"] + label span {
  position: absolute;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
  width: 50%;
  height: 25px;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.086),
    inset 10px 10px 10px rgba(0, 0, 0, 0.25), 0px 0px 5px rgb(255, 255, 255);
  color: white;
  user-select: none;
  &::after {
    transition: 0.3s ease-in-out;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
  }
}

.switch-toggle input[type="checkbox"]:checked + label span {
  left: 45%;
  box-shadow: 0px 0px 5px cyan;
  color: cyan;
  text-shadow: 0 0 5px cyan;
  &::after {
    background-color: cyan;
    box-shadow: 0 0 5px cyan;
    left: 5px;
  }
}
</style>
