<template>
  <section class="top-winners container" id="top-winners">
    <PageTitle title="Top winners" background="Winners"></PageTitle>
    <ul class="items-wn observe-item">
      <TopWinnerCard class="item-wn" v-for="(item, i) in finallTopWinners" :key="i">
        <span class="item-wn-head"></span>
        <!-- ================avatar=================== -->
        <img
          v-if="item.image"
          class="card-avatar-wn"
          :src="require(`@/assets/images/running-card/${item.image}`)"
          alt=""
        />
        <!-- ================avatar=============== -->
        <!-- ================information=================== -->
        <div class="inforamtions">
          <h2 class="name">
            {{ item.username ? item.username : "User name" }}
          </h2>
          <p class="about Q-font">
            {{ item.verified ? "Verified Account" : "Not Verified" }}
          </p>
          <p class="about Q-font">
            {{ item.status }}
          </p>
          <div class="fav">
            <h4>Joind Tournaments</h4>
            <h5>{{ item.tournaments_count }}</h5>
            <button class="profile-btn">View Profile</button>
          </div>
        </div>
        <!-- ================information=============== -->
      </TopWinnerCard>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TopWinnerCard from "./TopWinnerCard.vue";
export default {
  data() {
    return {
      finallTopWinners: [],
      localTopWinners: [
        {
          username: "username 7",
          image: "card7.jpg",
        },
        {
          username: "username 8",
          image: "card8.jpg",
        },
        {
          username: "username 9",
          image: "card9.jpg",
        },
        {
          username: "username 10",
          image: "card10.jpg",
        },
        {
          username: "username 11",
          image: "card11.jpg",
        },
        {
          username: "username 6",
          image: "card6.jpg",
        },
        {
          username: "username 1",
          image: "card1.jpg",
        },
        {
          username: "username 2",
          image: "card2.jpg",
        },
        {
          username: "username 3",
          image: "card3.jpg",
        },
        {
          username: "username 4",
          image: "card4.jpg",
        },
        {
          username: "username 5",
          image: "card5.jpg",
        },
      ],
    };
  },
  components: {
    TopWinnerCard,
  },
  mounted() {
    
    this.handleLocalImages();
  },
  computed: {
    ...mapGetters(["topWinners"]),
  },
  methods: {
    handleLocalImages() {
      this.finallTopWinners = this.topWinners.map((element, i) => {
        let image = this.localTopWinners[i] ? this.localTopWinners[i].image : null;
        
        if (image) return { ...element, image };
        else return element;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.top-winners {
  padding-bottom: 40px;
  .items-wn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include lg {
      font-size: 15px;
    }
    @include md {
      font-size: 12px;
    }
    @include sm {
      font-size: 0.47em;
    }
    .item-wn {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      margin-bottom: 1em;
      .item-wn-head {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 75%;
        width: 100%;
        background: #340078;
        border-top-left-radius: 16px;
      }
      .card-avatar-wn {
        position: absolute;
        top: 0.625em;
        left: 50%;
        width: 60%;
        min-height: 40%;
        object-fit: cover;
        aspect-ratio: 1;
        z-index: 2;
        transform: translateX(-50%);
        border-radius: 50%;
        border: 0.3em solid #340078;
      }
      .inforamtions {
        position: absolute;
        top: 25%;
        width: 100%;
        bottom: 0;
        z-index: 1;
        background: var(--color3);
        // border-top: 4px solid var(--color7);
        border-bottom-right-radius: 1em;
        padding: 0.625em;
        padding-top: 5.625em;
        text-align: center;
        .name {
          color: var(--color6);
          // text-align: center;
          font-size: 1.1875em;
          font-weight: 300;
        }
        .about {
          font-style: italic;
          font-size: 0.875em;
          line-height: 1em;
          color: var(--color6);
          font-weight: 200;
        }
        .fav {
          color: #2d90c2;
          font-weight: 200;
          margin-top: 0.625em;
          .profile-btn {
            width: 100%;
            font-size: 1em;
            background: var(--color1);
            padding: 0.3125em 0;
            border-radius: 0.75em 0 0.75em 0;
            margin-top: 1.5625em;
          }
        }
      }
    }
  }
}
</style>
