<template>
  <div class="alerts">
    <transition-group tag="ul" name="list-items">
      <li
        class="alert"
        v-for="alert in alerts"
        :key="alert.type"
        :class="setClass(alert)"
      >
        <i
          class="index bx bxs-error-circle bx-tada"
          :class="setClass(alert)"
          v-if="alert.type === 'warning'"
        ></i>
        <i
          class="index bx bxs-error bx-tada"
          :class="setClass(alert)"
          v-else-if="alert.type === 'error'"
        ></i>
        <i class="index bx bxs-check-circle bx-tada" :class="setClass(alert)" v-else></i>
        <span class="message">{{ alert.message }}</span>
        <i class="close bx bxs-x-circle" @click="close(alert)"></i>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["alerts"]),
  },
  methods: {
    ...mapMutations(["clearAlerts"]),
    close(item) {
      // this.alerts.splice(this.alerts.indexOf(item), 1);
      this.clearAlerts();
    },
    setClass(alert) {
      return alert.type === "success"
        ? "success"
        : alert.type === "warning"
        ? "warning"
        : "error";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.alerts {
  position: fixed;
  top: 0px;
  right: 0px;
  transform: translate(-50px, 30px);
  z-index: 1000;
  @include sm {
    transform: translate(-10px, 20px);
    font-size: 10px;
  }
}
.list-items {
}
.alert {
  /* width: 65%; */
  min-width: 17.5em;
  min-height: 3.75em;
  padding: 0 0.9375em 0 0.3125em;
  border-radius: 2.1875em;
  list-style: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.671);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
}
.alert.error {
  background: #ff3434;
}
.alert.warning {
  background: #ffc107;
}
.alert.success {
  background: #26bf60;
}
.alert .index {
  background: rgb(255, 255, 255);
  padding: 0.46em;
  font-size: 1.75em;
  border-radius: 2.1875em;
}
.alert .message {
  padding: 0 1em;
}
.alert .close {
  font-size: 1.375em;
  cursor: pointer;
}
.alert .index.success {
  color: #26bf60;
}
.alert .index.error {
  color: #ff3434;
}
.alert .index.warning {
  color: #ffc107;
}
.alert:nth-child(1) {
  transition-delay: 0.15s;
}
.alert:nth-child(2) {
  transition-delay: 0.3s;
}
.alert:nth-child(3) {
  transition-delay: 0.45s;
}
.alert:nth-child(4) {
  transition-delay: 0.6s;
}
.list-items-enter,
.list-items-leave-to {
  transform: translateX(1000px);
}
.list-items-enter-active,
.list-items-leave-active {
  transition: all 0.5s ease;
}
.list-items-enter-to,
.list-items-leave {
  transform: translateX(0px);
}
</style>
