<template>
  <div
    class="leaderboard-container"
    style="display: flex; align-items: center"
    v-if="isLoading"
  >
    <BaseLoading></BaseLoading>
  </div>
  <div class="leaderboard-container" v-else>
    <div class="no-result" v-if="!leaderboardInfo.length && !topRanks.length">
      <h4>Leaderboard Is Empty !</h4>
    </div>
    <div v-else>
      <h2 class="top-ranks-label">Top Players</h2>
      <ul class="top-ranks">
        <li
          v-for="(user, i) in topRanks"
          :key="i"
          :style="`background:${userBackgroundConverter(user.rank)}`"
        >
          <i
            class="rank-icon bx bxs-crown"
            :style="`color:${userColorConverter(user.rank)}`"
            ><p class="rank Q-font">{{ user.rank }}</p></i
          >
          <img
            class="image"
            :src="
              user.user.avatar
                ? storageImageLink(user.user.avatar)
                : require('@/assets/images/profile/avatar_2.png')
            "
            :style="`border: 4px solid ${userColorConverter(user.rank)}`"
            alt="User picture"
          />
          <div class="username" :style="`color:${userColorConverter(user.rank)}`">
            {{ user.user.username ? user.user.username : user.user.name }}
          </div>
          <section class="score">
            <h3 class="Q-font">score</h3>
            <div class="Q-font">
              {{ user.score }}
            </div>
          </section>
        </li>
      </ul>
      <h3 class="other-ranks-label">Other Player Ranks</h3>
      <ul class="other-ranks">
        <li v-for="(user, i) in leaderboardInfo" :key="i">
          <div class="avatar-container">
            <i class="rank-icon bx bxs-trophy"
              ><p class="rank Q-font">{{ user.rank }}</p></i
            >
            <img
              class="image"
              :src="
                user.user.avatar
                  ? storageImageLink(user.user.avatar)
                  : require('@/assets/images/profile/avatar_2.png')
              "
              alt="User picture"
            />
          </div>
          <div class="username">
            {{ user.user.username ? user.user.username : user.user.name }}
          </div>
          <section class="score">
            <h3 class="Q-font">score</h3>
            <div class="Q-font">
              {{ user.score }}
            </div>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      isLoading: false,
      leaderboardInfo: [],
    };
  },
  created() {
    this.fetchTournamentLeaderboard();
  },
  methods: {
    ...mapActions(["getTournamentLeaderboard"]),
    fetchTournamentLeaderboard() {
      this.isLoading = true;
      this.getTournamentLeaderboard(this.item.id)
        .then((res) => {
          this.isLoading = false;
          this.leaderboardInfo = res.data.result;
          console.log(this.leaderboardInfo);
          if (this.leaderboardInfo.length <= 3) {
            this.topRanks = this.leaderboardInfo.splice(0, this.leaderboardInfo.length);
          } else {
            this.topRanks = this.leaderboardInfo.splice(0, 3);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    userBackgroundConverter(rank) {
      if (rank === 1) return "#81742e";
      if (rank === 2) return "#704d2b";
      if (rank === 3) return "#5c5c5c";
    },
    userColorConverter(rank) {
      if (rank === 1) return "gold";
      if (rank === 2) return "#cd7f32";
      if (rank === 3) return "silver";
    },
    storageImageLink(avatar) {
      if (avatar.includes("http")) return avatar;
      else return `${process.env.VUE_APP_ROOT_STORAGE}/${avatar}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.leaderboard-container {
  background: rgb(17, 22, 37);
  padding: 10px 0;
  // min-height: 450px;
  @include md {
    font-size: 13px;
  }
  @include sm {
    font-size: 10px;
  }
  .top-ranks-label {
    text-align: center;
    color: white;
  }
  .top-ranks {
    display: flex;
    padding: 0.625em 0;
    li {
      margin: 0 0.5%;
      width: 32%;
      padding: 0.9375em 0;
      border-radius: 0.3125em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .rank-icon {
        font-size: 3.125em;
        position: relative;
        .rank {
          position: absolute;
          font-size: 0.275em;
          color: black;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -80%);
        }
      }
      .image {
        width: clamp(6.25em, 90%, 7em);
        // height: 42%;
        border-radius: 50%;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
      .username {
        margin-top: 0.625em;
        font-weight: 900;
        // @include sm {
        //   font-size: 12px;
        // }
      }
      .score {
        margin-top: 0.625em;
        font-style: italic;
        text-align: center;
        h3 {
          margin-bottom: -0.3125em;
        }
      }
    }
  }
  .other-ranks-label {
    color: white;
    margin-left: 0.75em;
  }
  .other-ranks {
    display: flex;
    padding: 0.625em 0;
    overflow: scroll;
    width: 100%;
    li {
      background: #143141;
      min-width: 24.3%;
      height: 9.375em;
      margin: 0.3125em 0.125em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.3125em;
      .avatar-container {
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        .rank-icon {
          font-size: 2.1875em;
          color: var(--color7);
          position: absolute;
          top: -5%;
          right: 15%;
          .rank {
            position: absolute;
            font-size: 0.4em;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            color: black;
          }
        }
        .image {
          border-radius: 50%;
          border: 0.1875em solid var(--color7);
          object-fit: cover;
          background: #0e4f72;
          aspect-ratio: 1/1;
        }
      }
      .username {
        font-size: 0.625em;
        margin-top: 0.1875em;
        font-weight: 900;
        color: var(--color7);
        // @include sm {
        //   font-size: 6px;
        // }
      }
      .score {
        margin-top: 0.5em;
        font-style: italic;
        text-align: center;
        font-size: 0.75em;
        color: white;
        h3 {
          margin-bottom: -0.3125em;
        }
      }
    }
  }
  .no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26.25em;
    color: #0e4f72;
  }
}
</style>
