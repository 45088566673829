<template>
  <div class="button" @click="click"><slot></slot></div>
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.button {
  font-size: 18px;
  font-weight: bold;
  border: 3px solid var(--color7);
  border-radius: 5px;
  padding: 5px 20px;
  color: var(--color7);
  cursor: pointer;
  white-space: nowrap;
  text-shadow: 0 0 10px currentColor;
  position: relative;
  perspective: 200px;
  transition: all 0.2s linear;
  &::before {
    pointer-events: none;
    position: absolute;
    content: "";
    width: 100%;
    height: 110%;
    top: 110%;
    background: currentColor;
    left: -5.5%;
    border-radius: 10px;
    transform: rotateX(90deg);
    filter: blur(30px);
    transition: all 0.2s linear;
  }
  &:hover {
    background-color: var(--color7);
    color: black;
    box-shadow: 0 0 14px 5px var(--color7);
    &::before {
      background: var(--color7);
      width: 150%;
      height: 150%;
      left: -27%;
      filter: blur(30px);
    }
  }
  @include sm {
    font-size: 12px;
    padding: 5px 8px;
  }
}
</style>
