import baseUrl from "../../plugins/axios/axios.js";
const state = {
  token: null,
  user: null,
};

const getters = {
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem("user", JSON.stringify(payload));
  },

  setToken(state, payload) {
    state.token = payload;
    localStorage.setItem("token", payload);
  },
};

const actions = {
  /**
   * Register
   */
  register({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/auth/register", payload)
        .then((result) => {
          let token = result.data.access_token;
          let user = result.data.user;

          commit("setUser", user);
          commit("setToken", token);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * Login
   */
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/auth/login", payload)
        .then((result) => {
          if (result.data.access_token) {
            let token = result.data.access_token;
            let user = result.data.user;

            commit("setUser", user);
            commit("setToken", token);
          }
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  autoLogin({ commit }) {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      commit("setUser", JSON.parse(localStorage.getItem("user")));
      commit("setToken", localStorage.getItem("token"));
    }
  },
  logout({ commit }) {
    // localStorage.clear();
    commit("setUser", null);
    commit("setToken", null);
    localStorage.clear();
  },

  setThirdpartyToken({ commit }, token) {
    commit("setToken", token);
  },

  /**
   * getUser
   */
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("users/profile/me")
        .then((result) => {
          let user = result.data.result;
          commit("setUser", user);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * getConfigs
   */
  getConfigs() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/helper/configs")
        .then((result) => {
          resolve(result.data.result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  setUserManually({ commit }, payload) {
    commit("setUser", payload);
  },

  /**
   * Refresh
   */
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/auth/refresh")
        .then((result) => {
          // i call this just for that the user still wave correct token if not he get 401 and axios kick him out
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },
  googleLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/auth/google", payload)
        .then((result) => {
          let token = result.data.access_token;
          let user = result.data.user;

          commit("setUser", user);
          commit("setToken", token);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * Email verify
   */
  EmailVerify() {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/auth/verify-request")
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * check email verify is success
   */
  getEmailVerify(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`users/auth/email-verify-check/${payload}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * generate 2fa
   */
  generateTwoFa() {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/auth/2fa/generate")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * change Two Fa Status
   */
  changeTwoFaStatus(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/auth/2fa/edit", payload)
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   * Verify 2fa
   */
  verifyTwoFa({ commit }, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/auth/2fa/verify", payload)
        .then((result) => {
          let token = result.data.access_token;
          let user = result.data.user;

          commit("setUser", user);
          commit("setToken", token);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  },

  resetPasswordEmailSend(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/password-reset/send-email", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  resetPasswordCheckToken(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/password-reset/check-token", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
  resetPasswordConfirmNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/password-reset/change", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  //   autoSetUser(context) {
  //     if (localStorage.getItem("user")) {
  //         context.commit("setUser", JSON.parse(localStorage.getItem("user")));
  //     }
  //     if (localStorage.getItem("token")) {
  //         context.commit("setToken", localStorage.getItem("token"));
  //     }
  // },

  // setThirdpartyToken({ commit }, token) {
  //   commit("setToken", token);
  // },
  // setThirdpartyUser({ commit }, user) {
  //
  //   commit("setUser", user);
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
