<template>
  <div class="input-box" :class="errors.length > 0 ? 'error' : ''">
    <input
      :type="type"
      class="animated-input"
      :class="enteredValue ? 'focused' : ''"
      :value="value"
      :max="max ? max : ''"
      @input="updateValue($event.target.value)"
    />
    <span class="animated-input-label">{{ label }}</span>
    <p class="error-message">{{ errors[0] }}</p>
    <i class="icon" :class="icon"></i>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    max: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    validation: {
      type: Array,
      required: false,
    },
    checkValidationFlag: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isValid: false,
      errors: [],
      enteredValue: "",
    };
  },
  watch: {
    checkValidationFlag(v) {
      if (v === true) {
        this.checkValidation();
      }
    },
  },
  created() {
    if (this.value) {
      this.enteredValue = this.value;
      this.checkValidation();
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value); //this line so neccessery(v-model working on this component because of this line )
      this.enteredValue = value;
      this.checkValidation();
    },
    checkValidation() {
      if (this.validation) {
        this.errors = [];
        this.requiredValidation();
        this.emailValidation();
        this.minLengthValidation();
      }
      this.$emit("isValid", this.isValid);
    },
    requiredValidation() {
      if (this.validation.find((ele) => ele === "required")) {
        if (!this.enteredValue) {
          this.isValid = false;
          this.errors.push("*This field is required. ");
        } else {
          this.isValid = true;
        }
      }
    },
    emailValidation() {
      if (this.validation.find((ele) => ele === "emailRules")) {
        if (
          !/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(this.enteredValue)
        ) {
          this.isValid = false;
          this.errors.push("*Email is not valid !");
        } else {
          this.isValid = true;
        }
      }
    },
    minLengthValidation() {
      if (this.validation.find((ele) => ele === "minLengthRules")) {
        if (this.enteredValue.length < 3) {
          this.isValid = false;
          this.errors.push("This filed must be at least 3 characters");
        } else {
          this.isValid = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-box {
  position: relative;
  .animated-input {
    width: 100%;
    height: 44px;
    padding: 0 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: all 0.8s ease;
    z-index: 1;
    color: white;
    &:focus,
    &.focused {
      border: 2px solid var(--color7);
      transition: all 0.3s ease;
      box-shadow: 0px 0px 4px var(--color7);
      background: transparent;
    }
    &.focused ~ .icon {
      color: var(--color7);
    }
    &:focus + .animated-input-label,
    &.focused + .animated-input-label {
      background: var(--color7);
      transform: translateY(-50%) scale(0.8);
      transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
      color: rgb(255, 255, 255);
      border-radius: 5px;
      border-left: 1px solid var(--color7);
      border-right: 1px solid var(--color7);
      padding: 0 20px;
    }
  }
  .animated-input-label {
    position: absolute;
    left: 15px;
    top: 0;
    color: grey;
    transform: translateY(90%);
    z-index: 0;
    transition: transform 0.5s ease;
    pointer-events: none;
    padding: 0 8px;
    font-size: 14px;
  }

  .error-message {
    min-height: 28px;
    font-size: 11px !important;
    display: flex;
    align-items: center;
    text-align: start !important;
    color: red !important;
  }

  &.error {
    .animated-input {
      border: 2px solid red;
      box-shadow: 0px 0px 6px red;
      &:focus + .animated-input-label,
      &.focused + .animated-input-label {
        background: red;
        color: white;
        border-left-color: red;
        border-right-color: red;
      }
      &.focused ~ .icon {
        color: red;
      }
    }
    .icon {
      color: red;
    }
  }
  .icon {
    position: absolute;
    top: 9px;
    right: 8px;
    font-size: 26px;
    color: grey;
  }
}
</style>
