<template>
  <div class="no-res">
    <h1>No Result !!!</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.no-res {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color8);
  h1 {
    @include sm {
      font-size: 15px;
    }
  }
}
</style>
