<template>
  <div class="menu-crousel">
    <div class="carousel-items observe-item">
      <div
        class="carousel-item"
        v-for="(slide, i) in slides"
        :key="i"
        :class="`${
          i === current
            ? 'current'
            : i === prev
            ? 'prev'
            : i === next
            ? 'next'
            : i === bPrev
            ? 'before-prev'
            : i === aNext
            ? 'after-next'
            : ''
        }`"
        @click="click(slide, i)"
      >
        <!-- background image -->
        <img
          src="@/assets/images/running-card/card2.jpg"
          alt="image"
          class="image"
          v-if="!slide.image"
        />
        <img :src="imageLinkFormatter(slide.image)" alt="image" class="image" v-else />
        <!-- background image -->
        <!-- logo -->
        <img
          class="tr-logo"
          src="@/assets/images/running-card/tournametsLogo1.png"
          alt=""
          width="55%"
        />
        <!-- logo -->
        <!-- lable -->
        <TimeRemainingLable
          :item="slide"
          :index="i"
          :current="current"
        ></TimeRemainingLable>
        <!-- lable -->
        <!-- join button -->
        <button
          class="tr-join-btn lt-hover"
          :class="i === current ? 'animation' : ''"
          @click.stop="joinTournament(slide)"
          v-if="token"
          :disabled="slide.is_joined"
        >
          <section v-if="slide.is_joined" style="color: orange">Joined</section>
          <section v-else>
            <span style="margin-right: 5px">join For</span>
            <span
              v-for="(v, n, i) in JSON.parse(slide.entry_price)"
              :key="i"
              style="margin-right: 5px"
            >
              <span>{{ v.amount }}</span
              ><span style="font-size: 10px">{{ v.coin }}</span>
            </span>
          </section>
        </button>
        <button
          class="tr-join-btn lt-hover"
          :class="i === current ? 'animation' : ''"
          v-else
        >
          <span style="margin-right: 5px">join Now</span>
        </button>
        <!-- join button -->
        <!-- information section -->
        <div class="information-section">
          <div class="information-wrapper">
            <div class="information-content">
              <h3 style="color: white">{{ slide.name }}</h3>
              <Rewards :rewards="slide.rewards"></Rewards>
              <h4>{{ slide.game.name }}</h4>
            </div>
          </div>
        </div>
        <!-- information section -->
      </div>
    </div>
    <!-- join confirmation modal -->
    <BaseModal
      :dialog="joinConfirmationDialog"
      @close="joinConfirmationDialog = false"
      title="join Tournament confirm"
    >
      <JoinConfirmation
        :item="itemSelected"
        @cancleJoin="joinConfirmationDialog = false"
        @joinedTrSuccessfully="joinedTrSuccessfully"
      ></JoinConfirmation>
    </BaseModal>
    <!-- join confirmation modal -->
  </div>
</template>

<script>
// import Rewards from "../../../views/home/tournaments/Rewards.vue";
import TimeRemainingLable from "./TimeRemainingLable.vue";
import {
  dateFormater,
  timeFormater,
  remainingTime,
} from "../../../mixins/calendarFormater.js";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["slides", "current", "next", "prev", "bPrev", "aNext"],
  mixins: [dateFormater, timeFormater, remainingTime],
  data() {
    return {
      // rewards: '{"1": 50, "2": 30, "3": 20}',
      joinConfirmationDialog: false,
      itemSelected: null,
    };
  },
  watch: {
    joinConfirmationDialog(val) {
      val ? this.$emit("PauseInterval") : this.$emit("resumeInterval");
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },
  components: { TimeRemainingLable },
  created() {},
  methods: {
    ...mapActions(["getFrontRunningTournamets"]),
    click(slide, index) {
      this.$emit("clicked", slide, index);
    },
    joinTournament(slide) {
      this.itemSelected = slide;
      this.joinConfirmationDialog = true;
    },
    joinedTrSuccessfully() {
      this.joinConfirmationDialog = false;
      this.$router.push({ name: "tournaments" });
    },
    imageLinkFormatter(img) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${img}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.menu-crousel {
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  @include md {
    font-size: 9px;
  }
  // @include sm {
  //   font-size: 16px;
  // }
  .carousel-items {
    position: relative;
    perspective: 700px;
    width: 100%;
    min-height: 37.5em;
    .carousel-item {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all 1s ease;
      border: 0.3em solid #0070a0d6;
      border-radius: 1.5em;

      &.current {
        opacity: 1;
        transform: translate(-50%, -50%);
        z-index: 50;
        width: 97%;
        max-width: 32em;
        height: 26em;
        transition: all 1s ease;
        cursor: pointer;
      }
      &.before-prev {
        opacity: 0;
        transform: translate(88%, -50%) scale(0.3) rotateY(14deg);
        transition: all 0.5s ease;
        width: 100%;
        max-width: 30em;
        height: 20em;
      }
      &.prev {
        opacity: 0.8;
        transform: translate(52%, -50%) rotateY(20deg);
        transition: all 1s ease;
        width: 100%;
        max-width: 28em;
        height: 22em;
        cursor: pointer;
      }
      &.next {
        opacity: 0.8;
        transform: translate(-152%, -50%) rotateY(-20deg);
        transition: all 1s ease;
        width: 100%;
        max-width: 28em;
        height: 22em;
        cursor: pointer;
      }
      &.after-next {
        opacity: 0;
        transform: translate(-188%, -50%) scale(0.3) rotateY(-14deg);
        transition: all 0.5s ease;
        width: 100%;
        max-width: 30em;
        height: 20em;
      }
      .image {
        object-fit: cover;
        border: none;
        border-radius: 1em;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
      .tr-logo {
        left: 50%;
        transform: translate(-50%);
        position: absolute;
        top: -5em;
      }
      .tr-join-btn {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        padding: 0.625em 1.875em;
        border-radius: 1.25em;
        background: rgba(0, 128, 0, 0.87);
        border: 0.125em solid rgb(0, 255, 0);
        white-space: nowrap;
        z-index: 0;
        transition: all 0.3s ease;
        @include md {
          font-size: 9px;
        }
        &.animation {
          z-index: 100;
          &::before {
            content: "";
            position: absolute;
            top: -0.0625em;
            left: -0.0625em;
            width: 100%;
            height: 100%;
            border-radius: 1.25em;
            border: 0.0625em solid rgb(0, 255, 0);
            animation: scale 1s ease infinite;
          }
        }
        span {
          // margin: 0 0.3125em;
          color: white;
        }
      }
      .information-section {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        z-index: 10;
        overflow: hidden;
        border-radius: 1em;
        .information-wrapper {
          position: absolute;
          width: 110%;
          height: 80%;
          bottom: -40%;
          left: 50%;
          transform: translate(-50%);
          border-radius: 50%;
          background: #0070a0ec;
          display: flex;
          flex-direction: column;
          align-items: center;
          .information-content {
            margin-top: 5%;
            text-align: center;
            h3 {
              color: var(--color6);
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
