<template>
  <button class="logo-container animate" >
    <img src="@/assets/logo/minigames-logo.png" alt="" class="logo" />
    <span style="color: #fcca00">MINI</span>
    <span style="color: #01e6fb">GAMES</span>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.logo-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: none;
      @include md{
        font-size: 10px;
      }
      .logo {
        width: 4.5em;
        transition: all 0.5s ease;
      }
      span {
        font-size: 1.5em;
        font-weight: 800;
        transition: all 0.5s ease;
      }
      &:hover {
        span {
          text-shadow: 0 0 .3125em white;
        }
        .logo {
          filter:hue-rotate(360deg);
        }
      }
    }
</style>
