<template>
  <li class="tournametn-item">
    <img
      :src="imageLinkFormatter(tournament.image)"
      alt=""
      class="img"
      v-if="tournament.image"
    />
    <img
      src="@/assets/images/running-card/card2.jpg"
      alt=""
      class="img"
      v-else
    />
    <h3 class="name">{{ tournament.name }}</h3>
    <div class="date center">
      <section class="date-and-time">
        <div class="start">
          <p>{{ convertDate(tournament.start_time, "DMMMYYYY") }}</p>
          <p>{{ convertDate(tournament.start_time, "HH:mm") }}</p>
        </div>
        <span>-</span>
        <div class="finish">
          <p>{{ convertDate(tournament.finish_time, "DMMMMYYYY") }}</p>
          <p>{{ convertDate(tournament.finish_time, "HH:mm") }}</p>
        </div>
      </section>
      <div
        class="remaining-time"
        :class="rTimeFlag ? 'have-time' : 'time-over'"
        v-if="rTime !== null"
      >
        <p class="r-time">
          {{
            rTime
              ? rTime
              : "00 Days, 00:00:00" /* this vareable is comming from mixin */
          }}
        </p>
        <h4>{{ !rTimeFlag ? "Finished" : "Lefted Time" }}</h4>
      </div>
      <Loading v-else></Loading>
    </div>
    <div class="entry-price" v-if="!tournament.is_joined">
      <div class="entry-label center">Entry price</div>
      <div
        class="entry-value center"
        v-for="(v, n, i) in computedEntryPrice"
        :key="i"
      >
        <span>{{ v.amount }} </span>
        <span class="entry-coin">{{ v.coin }}</span>
      </div>
    </div>
    <div class="entry-price" v-else>
      <span class="entry-label center">Score</span>
      <div class="entry-value center">{{ tournament.max_score }}</div>
    </div>
    <div class="rewards-container center">
      <Rewards
        :rewards="tournament.rewards"
        :totalPrice="tournament.total_price"
      ></Rewards>
    </div>
    <div class="actions">
      <Tooltip text="Tournament details">
        <button style="background: none" @click="showDetails(tournament)">
          <i class="bx bxs-info-circle" style="color: var(--color7)"></i>
        </button>
      </Tooltip>
      <Tooltip text="Leaderboard">
        <button style="background: none" @click="showLeaderboard(tournament)">
          <i class="bx bxs-bar-chart-alt-2" style="color: var(--color2)"></i>
        </button>
      </Tooltip>
      <Tooltip
        :text="tournament.is_joined ? 'Go to game' : 'Join Tournament'"
        v-if="rTime !== null"
      >
        <button
          style="background: none"
          v-if="rTimeFlag"
          @click="joinTournament(tournament)"
        >
          <i
            class="bx bxs-user-plus bx-tada"
            style="color: rgb(0, 255, 0)"
            v-if="!tournament.is_joined"
          ></i>
          <i
            class="bx bx-link-external bx-tada"
            style="color: white"
            v-else
          ></i>
        </button>
      </Tooltip>
      <Loading v-else></Loading>
    </div>
  </li>
</template>

<script>
import { mapActions } from "vuex";
import {
  dateFormater,
  timeFormater,
  remainingTime,
} from "../../../mixins/calendarFormater.js";
export default {
  props: ["tournament"],
  mixins: [dateFormater, timeFormater, remainingTime],
  data() {
    return {
      score: 0,
    };
  },
  computed: {
    computedEntryPrice() {
      return JSON.parse(this.tournament.entry_price);
    },
  },
  created() {
    this.checkForStartOrFinish();
    // if(this.tournament.is_joined){
    //   this.fetchScore();
    // }
  },
  destroyed() {
    clearInterval(this.rTInterval);
  },
  methods: {
    ...mapActions(["getActiveJoinedScore"]),
    checkForStartOrFinish() {
      const { start_time, finish_time } = this.tournament;

      const now = this.currentTimeStamp();
      const start = this.dateTimeStamp(start_time);

      let diff = start - now;

      if (diff > 0) {
        console.log(this.tournament.id, "not started");
      } else {
        console.log(this.tournament.id, "started");
      }
      // check for is started or not

      this.remainingTime(diff > 0 ? start_time : finish_time); //this function is comming from mixin;
    },
    showDetails(item) {
      this.$emit("showDetails", item);
    },
    joinTournament(item) {
      this.$emit("joinTournament", item);
    },
    showLeaderboard(item) {
      this.$emit("showLeaderboard", item);
    },
    imageLinkFormatter(img) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${img}`;
    },
    // fetchScore(){
    //   this.getActiveJoinedScore(this.tournament.id).then((res)=>{
    //
    //   })
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.tournametn-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 5px;
  margin: 0.3125em 0;
  padding: 0.5em;
  display: grid;
  grid-template-columns: 13% 26% 26% 7% 20% 8%;
  transition: all 0.3s ease;
  @include md {
    grid-template-columns: none;
    flex: 0 0 48.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 0 !important;
    flex: 0 0 100%;
  }
  &:hover {
    transform: scale(1.002);
    box-shadow: 0.125em 0.125em 0.3125em black;
  }
  .img {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    border: 4px solid var(--color7);
    margin-right: 3%;
    @include md {
      margin: auto;
    }
  }
  .name {
    color: #e8eaf2;
    @include md {
      text-align: center;
      font-size: 1.0625em;
    }
  }
  div {
    color: #8799e0;
    @include md {
      margin: 0.1875em 0;
    }
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .date {
    flex-direction: column;
    align-items: center;
    p {
      font-family: "Quantico", sans-serif;
      font-size: 0.875em;
      font-style: italic;
      text-align: center;
      @include md {
        font-size: 0.6875em;
      }
    }
    span {
      margin: auto 0.125em;
    }
    .date-and-time {
      display: flex;
    }
    .remaining-time {
      text-align: center;
      &.have-time {
        .r-time {
          color: #00ff00;
        }
        h4 {
          color: #00ff00;
        }
      }
      &.time-over {
        .r-time {
          color: #ff4343;
        }
        h4 {
          color: #ff4343;
        }
      }
    }
  }
  .entry-price {
    .entry-label {
      color: white;
      margin-right: 0.125em;
      font-size: 0.5625em;
      white-space: nowrap;
    }
    .entry-value {
      color: rgb(0, 255, 0);
      margin-right: 0.125em;
      display: flex;
      align-items: center;
      .entry-coin {
        font-size: 0.625em;
        margin-left: 0.125em;
      }
    }
  }
  .rewards-container {
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    .bx {
      font-size: 1.875em;
    }
    button {
      width: 100%;
      max-width: 12.5em;
      margin: 0 auto;
      padding: 0.3125em 0;
      border-radius: 0.3125em;
      &:disabled {
        i {
          color: grey !important;
          animation: unset;
        }
      }
    }
  }
}
</style>
