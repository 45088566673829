
import baseUrl from "../../plugins/axios/axios.js";
const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  
  /**
   *get all purchases
   */
  getAllPurchases(_,params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/front/games/items`,{params:params})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *get user purchases
   */
  getUserPurchases(_,params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/users/games/items`,{params:params})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *Buy purchase Item
   */
  buyPurchaseItem(_,payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/games/items`, payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
