<template>
  <section class="line">
    <div class="container">
      <div class="line-left"></div>
      <div class="center-shape"></div>
      <div class="line-right"></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.line {
  height: 10px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .line-left {
      height: 2px;
      background: linear-gradient(to right, transparent, var(--color6));
      width: 38%;
      border-radius: 50px;
      @include md {
        width: 28%;
      }
    }
    .center-shape {
      width: 15%;
      height: inherit;
      border: 2px solid var(--color6);
      border-radius: 5px;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: -2px;
        width: 8px;
        height: 8px;
        border: 2px solid rgb(255, 255, 255);
        border-radius: 50%;
      }
      &:before {
        left: -20%;
        border-color: var(--color6);
        @include md {
          left: -50%;
        }
      }
      &:after {
        right: -20%;
        border-color: var(--color6);
        @include md {
          right: -50%;
        }
      }
    }
    .line-right {
      height: 2px;
      background: linear-gradient(to right, var(--color6), transparent);
      width: 38%;
      border-radius: 50px;
      @include md {
        width: 28%;
      }
    }
  }
}
</style>
