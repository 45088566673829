export const scrollLock = {
  methods: {
    scrollLock() {
      document.body.classList.add("stop-scrolling");
    },
    
  },
};
export const scrollUnlock = {
  methods: {
    scrollUnlock() {
      document.body.classList.remove("stop-scrolling");
    },
  },
};
