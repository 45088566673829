<template>
  <div class="avatar" ref="dropdownMenu">
    <img
      :src="avatarImage"
      width="100%"
      class="image"
      @click="toggleDrop"
      v-if="user.avatar"
    />
    <i class="profile-icon bx bxs-user-circle" @click="toggleDrop" v-else></i>
    <div v-show="dropOpen" id="dropdown">
      <div class="menu-content">
        <button class="menu-item" @click="$router.push({ name: 'dashboard' })">
          <span>Dashboard</span> <i class="bx bxs-dashboard"></i>
        </button>
        <button class="menu-item" @click="$router.push({ name: 'profile' })">
          <span>Profile</span> <i class="bx bxs-user"></i>
        </button>
        <button class="menu-item" @click="userLogout">
          <span>Logout</span> <i class="bx bxs-exit"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dropOpen: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    avatarImage() {
      return this.user.avatar
        ? this.storageImageLink
        : require("@/assets/images/profile/avatar.png");
    },
    storageImageLink() {
      if (this.user.avatar.includes("http")) return this.user.avatar;
      else return `${process.env.VUE_APP_ROOT_STORAGE}/${this.user.avatar}`;
    },
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    // important to clean up!!
    document.removeEventListener("click", this.documentClick);
  },
  methods: {
    ...mapActions(["logout"]),
    toggleDrop() {
      this.dropOpen = !this.dropOpen;
    },
    documentClick(e) {
      let el = this.$refs.dropdownMenu;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.dropOpen = false;
      }
    },
    userLogout() {
      this.logout();
      if (this.$route.name !== "landing") this.$router.replace({ name: "landing" });
      setTimeout(() => {
        //delay for element create on the DOM
        const loginBtn = document.getElementById("login-btn");
        loginBtn.style.opacity = 1;
      }, 1);
    },
    imageLink(image) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${image}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  @include sm {
    transform: scale(0.9);
  }
  .image {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    aspect-ratio: 1.1;
  }
  .profile-icon {
    font-size: 40px;
    color: rgb(255, 255, 255);
  }
  #dropdown {
    .menu-content {
      position: absolute;
      right: 0;
      width: 180px;
      padding: 10px 0;
      background: #005792;
      border-radius: 5px;
      .menu-item {
        padding: 10px 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgb(223, 252, 255);
        background: transparent;
        &:hover {
          background: #004675;
        }
      }
    }
  }
}
</style>
