import baseUrl from "../../plugins/axios/axios.js";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  //  Update Profile
  updateProfile(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/profile/update", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  //  Update password
  updatePassword(_, payload) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("users/profile/reset-password", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
