<template>
  <div id="app ">
    <div class="observer-object">.</div>
    <Alerts></Alerts>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <NewsModal />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import Alerts from "./components/alerts/Alerts.vue";
import NewsModal from "./components/common/NewsModal";
export default {
  data() {
    return {
      globalLoading: true,
    };
  },
  components: {
    Alerts,
    NewsModal,
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    if (this.token) {
      this.refresh();
    }
  },
  created() {
    this.autoLogin();
  },

  methods: {
    ...mapActions(["refresh", "autoLogin"]),
  },
};
</script>
<style lang="scss">
.observer-object {
  position: absolute;
  top: 0px;
  height: 1px;
}

//router-ainmation
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
.fade-leave-to,
.fade-enter {
  opacity: 0;
}

//router-ainmation
</style>
