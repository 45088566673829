<template>
  <div class="or">
    <span></span>
    <p>OR</p>
    <span></span>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
.or{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    font-size: 10px;
  }
  span{
    width: 43%;
    height: 1px;
    background: grey;
  }
}
</style>