import axios from "axios";
import store from "../../store/index.js";
import router from "../../router/index.js";
const baseUrl = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

baseUrl.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
baseUrl.interceptors.response.use(
  (response) => {
    //if request done successfully but we have an errors
    if (!response.data.success && response.data.message) {
      if (typeof response.data.errors === "object") {
        for (const [key, value] of Object.entries(response.data.errors)) {
          store.dispatch("setAlert", {
            type: "warning",
            message: value.toString(),
          });
        }
      } else {
        store.dispatch("setAlert", {
          type: "warning",
          message: response.data.errors,
        });
      }
    }
    //if request done successfully with success message
    if (
      response.data.success &&
      response.data.message &&
      response.config.method !== "get"
    ) {
      // for (const [key, value] of Object.entries(response.data.message)) {
      store.dispatch("setAlert", {
        type: "success",
        message: response.data.message,
      });
      // }
    }

    return Promise.resolve(response);
  },
  (error) => {
    let status = error.response.status;
    switch (status) {
      case 400:
        break;
      //Unauthenticated, token expire or wrong token(کاربر لاگین نیست)
      case 401:
        store.dispatch("logout");
        if (
          router.history.current.name !== "login" &&
          !router.history.current.name.includes("unauth") //if we are not in login page and unauth pages
        ) {
          router.push({ name: "landing" }).catch(() => {}); //go to landing
        } else {
          if (router.history.current.name !== "login") {
            store.dispatch("setReturnRoute", {
              name: router.history.current.name,
              params: router.history.current.params,
            });
          }
          router.push({ name: "login" }).catch(() => {}); //save name of the return route and go to login
        }
        break;
      case 402:
        break;
      //Unauthorized (لاگین هست اما دسترسی نداره)
      case 403:
        break;

      default:
        // if (status > 400 && status < 500) {
        //   store.dispatch("setAlert", {
        //     type: "error",
        //     message: "APPLICATION ERROR",
        //   });
        // }
        if (status >= 500) {
          store.dispatch("setAlert", {
            type: "error",
            message: "SERVER ERROR",
          });
        }

        break;
    }
    // if request break and we have an errors

    //for error
    if (error.response.data.error) {
      const errorMessage = error.response.data.error;
      store.dispatch("setAlert", {
        type: "error",
        message: errorMessage,
      });
    }

    if (error.response.data.errors) {
      if (error.response.status === 422) {
        // validation errors

        for (const [key, value] of Object.entries(error.response.data.errors)) {
          let errorMessage = value.toString();
          store.dispatch("setAlert", {
            type: "error",
            message: errorMessage,
          });
        }
      } else {
        const errorMessage = error.response.data.message;
        store.dispatch("setAlert", {
          type: "error",
          message: errorMessage,
        });
      }
    }

    // if (error.response.data.result.length && error.response.data.message) {
    //   for (const [key, value] of Object.entries(error.response.data.result)) {
    //     let val = value.toString();
    //   }
    // }

    // if (
    //   Array.isArray(error.response.data.errors) &&
    //   error.response.data.errors &&
    //   error.response.data.message
    // ) {
    //   for (const [key, value] of Object.entries(error.response.data.errors)) {
    //     let val = value.toString();
    //   }
    // }

    // if (
    //   error.response.data.message &&
    //   !Array.isArray(error.response.data.errors) &&
    //   !error.response.data.result.length
    // ) {
    // }

    return Promise.reject(error);
  }
);

export default baseUrl;
