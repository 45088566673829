import Vue from "vue";
import VueRouter from "vue-router";
import { defineAsyncComponent } from "vue";
import authRequired from "../utils/authRequired";
import Landing from "../views/Landing.vue";
import { setTimeout } from "core-js";

const Login = defineAsyncComponent(() => import("../views/auth/Login.vue"));
const Register = defineAsyncComponent(() =>
  import("../views/auth/Register.vue")
);
const ThirdpartyLogin = defineAsyncComponent(() =>
  import("../views/auth/ThirdpartyLogin.vue")
);
const Home = defineAsyncComponent(() => import("../views/home/Home.vue"));
const DashboardIndex = defineAsyncComponent(() =>
  import("../views/home/dashboard/DashboardIndex.vue")
);
const ProfileIndex = defineAsyncComponent(() =>
  import("../views/home/profile/ProfileIndex.vue")
);
const GamesIndex = defineAsyncComponent(() =>
  import("../views/home/games/GamesIndex.vue")
);
const GameIndex = defineAsyncComponent(() =>
  import("../views/home/games/game/GameIndex.vue")
);
const TournamentsIndex = defineAsyncComponent(() =>
  import("../views/home/tournaments/TournamentsIndex.vue")
);
const TicketsIndex = defineAsyncComponent(() =>
  import("../views/home/tickets/TicketsIndex.vue")
);
const WalletsIndex = defineAsyncComponent(() =>
  import("../views/home/wallets/WalletsIndex.vue")
);
const PaymentCallback = defineAsyncComponent(() =>
  import("../views/home/wallets/PaymentCallback.vue")
);
const PaymentsIndex = defineAsyncComponent(() =>
  import("../views/home/payments/PaymentsIndex.vue")
);
const PurchasesIndex = defineAsyncComponent(() =>
  import("../views/home/purchases/PurchasesIndex.vue")
);
const EmailVerifyCallback = defineAsyncComponent(() =>
  import("../views/home/profile/EmailVerifyCallback.vue")
);
const User = defineAsyncComponent(() => import("../views/home/User.vue"));
const PlayGame = defineAsyncComponent(() =>
  import("../views/home/playGame/PlayGame.vue")
);
const ResetPassword = defineAsyncComponent(() =>
  import("../views/auth/ResetPassword.vue")
);
const ResetPasswordCallback = defineAsyncComponent(() =>
  import("../views/auth/ResetPasswordCallback.vue")
);
const PrivacyPolicy = defineAsyncComponent(() =>
  import("../views/home/others/PrivacyPolicy.vue")
);
const TermsAndConditions = defineAsyncComponent(() =>
  import("../views/home/others/TermsAndConditions.vue")
);
const NotFound = defineAsyncComponent(() => import("../views/NotFound.vue"));

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/thirdparty-login",
    name: "thirdparty-login",
    component: ThirdpartyLogin,
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: EmailVerifyCallback,
  },
  {
    path: "privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },

  //play game route
  // {
  //   path: "/play-game/:gameId/:orientation",
  //   name: "play-game",
  //   component: PlayGame,
  // },
  //play game route
  //reset password routes
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/reset-password/redirect",
    name: "reset-password-redirect",
    component: ResetPasswordCallback,
  },
  //reset password routes

  {
    path: '*',
    name: "not-found",
    component: NotFound,
  },

  // Home routes
  {
    path: "/panel",
    name: "panel",
    redirect: "/home/dashboard",
    beforeEnter: authRequired,
    component: Home,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardIndex,
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileIndex,
      },
      {
        path: "games",
        name: "games",
        component: GamesIndex,
      },
      {
        path: "game/:id",
        name: "game",
        component: GameIndex,
      },

      {
        path: "tournaments",
        name: "tournaments",
        component: TournamentsIndex,
      },
      {
        path: "tickets",
        name: "tickets",
        component: TicketsIndex,
      },
      {
        path: "wallets",
        name: "wallets",
        component: WalletsIndex,
      },
      {
        path: "transaction-report",
        name: "paymentCallback",
        component: PaymentCallback,
      },

      {
        path: "payments",
        name: "payments",
        component: PaymentsIndex,
      },
      {
        path: "purchases",
        name: "purchases",
        component: PurchasesIndex,
      },
     
    ],
  },
  // Home routes

  // User routes
  {
    path: "/user",
    name: "user",
    component: User,
    children: [
      {
        path: "game/:id",
        name: "unauth-user-game",
        component: GameIndex,
      },
      {
        path: "purchases",
        name: "unauth-user-purchases",
        component: PurchasesIndex,
      },
    ],
  },
  // User routes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500);
  },
});


export default router;
