<template>
  <div>
    <transition name="overlay">
      <div class="overlay" v-if="dialog" @click="close"></div>
    </transition>
    <transition name="dialog">
      <div
        class="modal-container"
        :style="`${maxWidth ? `max-width: ${maxWidth}px` : ``}`"
        v-if="dialog"
      >
        <div class="mobile-close-handle" @click="close"></div>
        <section class="modal-header">
          <h3 name="header">{{ title ? title : "" }}</h3>
          <i class="bx bxs-x-square" @click="close"></i>
        </section>
        <div class="modal-body">
          <slot> default body </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { scrollLock, scrollUnlock } from "../../mixins/scrollControl.js";
import { swipe } from "../../mixins/swipe";
export default {
  props: ["dialog", "title", "maxWidth"],
  mixins: [scrollLock, scrollUnlock, swipe],
  watch: {
    dialog(val) {
      if (val) {
        this.scrollLock();
        // setTimeout(() => {
        //   const modal = document.querySelector(".modal-container");
        //   this.swipe(modal,"down",this.close);
        // }, 1);
      } else {
        this.scrollUnlock();
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.785);
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  min-width: 300px;
  width: 50%;
  max-width: 600px;
  height: fit-content;
  z-index: 1001;
  // overflow: hidden;
  box-shadow: 0 0px 20px black;
  border-radius: 5px;
  &::before {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(to right, var(--color7), var(--color2));
    z-index: -3;
    border-radius: 5px;
    @include md {
      display: none;
    }
  }
  @include md {
    border-radius: 25px 25px 0 0;
    width: 100%;
    max-width: unset;
    margin: unset;
    margin-top: auto;
    border: none;
  }
  .mobile-close-handle {
    width: 100%;
    height: 20px;
    background: grey;
    position: relative;
    display: none;
    &::before {
      content: "";
      position: absolute;
      background: white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 15%;
      height: 20%;
      border-radius: 25px;
    }
    @include md {
      display: block;
    }
  }
  .modal-header {
    padding: 10px 15px;
    color: white;
    background: linear-gradient(to right, var(--color7), var(--color2));
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    i {
      font-size: 25px;
      cursor: pointer;
      @include md {
        display: none;
      }
    }
    @include md {
      background: var(--color3);
      justify-content: center;
      border: none;
      font-size: 14px;
    }
    @include sm {
      font-size: 12px;
    }
  }
  .modal-body {
    width: 100%;
    border-top: none;
    @include md {
      border: none;
    }
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  45% {
    transform: scale(1.35);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
.overlay-leave,
.overlay-enter-to {
  opacity: 1;
}
.overlay-leave-active,
.overlay-enter-active {
  transition: all 0.8s ease;
}

.dialog-leave-active {
  animation: bounce-in 0.6s ease reverse;
  @include md {
    animation: slide-up 0.4s ease reverse;
  }
}
.dialog-enter-active {
  animation: bounce-in 0.6s ease;
  @include md {
    animation: slide-up 0.4s ease;
  }
}
</style>
