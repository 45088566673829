import baseUrl from "../../plugins/axios/axios.js";
const state = {
  slides: [],
  FRTournaments: [],
  topWinners: [],
};

const getters = {
  slides(state) {
    return state.slides;
  },
  topWinners(state) {
    return state.topWinners;
  },
  FRTournaments(state) {
    return state.FRTournaments;
  },
};

const mutations = {
  setSlides(state, payload) {
    state.slides = payload;
  },
  setTopWinners(state, payload) {
    state.topWinners = payload;
  },
  setFRTournaments(state, payload) {
    state.FRTournaments = payload;
  },
};

const actions = {
  /**
   * banners games fetch
   */
  getGames({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/front/games", { params: params })
        .then((result) => {
          commit("setSlides", result.data.result.data);
          resolve(result);
        })
        .catch((err) => {
          
          reject(err);
        });
    });
  },
  /**
   * Single game fetch
   */
  getSingleGame(_, id) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/front/games/${id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject(err);
        });
    });
  },

  /**
   * Single game fetch
   */
  getGameTournaments(_, info) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/front/games/${info.id}/tournaments/all`, { params: info.params })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject(err);
        });
    });
  },

  /**
   *get front Running tournaments
   */
  getFrontRunningTournamets({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/front/tournaments/running", { params: params })
        .then((result) => {
          commit("setFRTournaments", result.data.result.data);
          
          resolve();
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *get front Top Winners
   */
  getFrontTopWinners({ commit }) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/front/users/top/winners")
        .then((result) => {
          commit("setTopWinners", result.data.result);
          
          resolve();
        })
        .catch((err) => {
          
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
