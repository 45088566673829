<template>
  <div class="rm-wrapper">
    <h4 class="r-time">
      {{
        rTime
          ? rTime
          : "00 Days, 00:00:00" /* this vareable is comming from mixin */
      }}
    </h4>
  </div>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater.js";
export default {
  props: ["item", "index", "current"],
  mixins: [remainingTime],
  watch: {
    current(val) {
       clearInterval(this.rTInterval);
      if (val === this.index) {
        this.remainingTime(this.item.finish_time); //this function is comming from mixin
      } else clearInterval(this.rTInterval);
    },
  },
  created() {
    if(this.current, this.index) this.remainingTime(this.item.finish_time)
  },
  destroyed() {
    clearInterval(this.rTInterval);
  },
};
</script>

<style lang="scss" scoped>
.rm-wrapper {
  position: absolute;
  width: 100%;
  min-width: 190px;
  top: 20%;
  left: 0;
  background: linear-gradient(
    to right,
    transparent,
    var(--color3),
    var(--color3),
    transparent
  );
  padding: 2% 0;
  opacity: 0.97;
  text-align: center;
  h4 {
    color: var(--color7);
    text-shadow: 0 0 10px blue;
  }
}
</style>
