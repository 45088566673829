<template>
  <div class="join-confirmation">
    <p>Do you want to join in this tournament?</p>
    <div class="info">
      <div class="tr-name">
        <span>{{ item.name }}</span>
        <span>Id:{{ item.id }}</span>
      </div>
      <div class="tr-price">
        <h3>Entry Price</h3>
        <h3 class="values" style="display: flex">
          <div
            v-for="(v, n, i) in JSON.parse(item.entry_price)"
            :key="i"
            style="margin-right: 5px"
          >
            <span>{{ v.amount }}</span
            ><span style="font-size: 10px">{{ v.coin }}</span>
          </div>
        </h3>
      </div>

      <div class="user-balance">
        <h3>Your Balance:</h3>
        <Loading v-if="loading"></Loading>
        <h3 class="values" style="display: flex" v-else>
          <div v-for="(wallet, i) in targetWallets" :key="i">
            <span style="margin-right: 5px"
              >{{ wallet.balance ? wallet.balance : "0"
              }}<span style="font-size: 10px">{{ wallet.coin }}</span>
            </span>
            <!-- <span v-if="!wallet.balance" style="margin-right: 5px"
              >0<span style="font-size: 10px">{{ wallet }}</span></span
            > -->
          </div>
        </h3>
      </div>
      <div class="line"></div>
    </div>
    <div class="actions">
      <button class="btn error" @click="$emit('cancleJoin')">No</button>
      <button class="btn success" @click="confirmJoin">
        <Loading v-if="isLoading"></Loading>
        <span v-else>Yes</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { scrollUnlock } from "../../../mixins/scrollControl.js";
export default {
  mixins: [scrollUnlock],
  props: ["item"],
  data() {
    return {
      allWallets: [],
      targetWallets: [],
      loading: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchAllWallets();
  },
  methods: {
    ...mapActions(["getAllWallets", "JoinTournament"]),
    fetchAllWallets() {
      this.loading = true;
      this.getAllWallets()
        .then((res) => {
          this.allWallets = res.data.result;
          console.log(this.allWallets);
          this.comparePrice();
        })
        .catch(() => {
          this.scrollUnlock();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    comparePrice() {
      this.targetWallets = [];
      let entryPrice = JSON.parse(this.item.entry_price);
      console.log(entryPrice);

      for (const key in entryPrice) {
        this.targetWallets.push(
          this.allWallets.find((wallet) => wallet.coin == key.toUpperCase())
            ? this.allWallets.find(
                (wallet) => wallet.coin == key.toUpperCase() && wallet.commission === 0
              )
            : key
        );
      }
      console.log(this.targetWallets);
    },
    confirmJoin() {
      this.isLoading = true;
      this.JoinTournament(this.item.id)
        .then((result) => {
          this.$emit("joinedTrSuccessfully");
        })
        .catch((err) => {
          this.$emit("cancleJoin");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.join-confirmation {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  max-height: 80vh;
  overflow: auto;
  color: rgb(185, 185, 185);
  .info {
    .tr-name {
      color: var(--color7);
      font-size: 20px;
      display: flex;
      justify-content: space-between;
    }
    .tr-price {
      margin-top: 20px;
      color: #229861;
      display: flex;
      justify-content: space-between;
    }
    .user-balance {
      color: orange;
      display: flex;
      justify-content: space-between;
    }
    .values {
      min-width: 120px;
    }
    .line {
      height: 1px;
      background: linear-gradient(to right, transparent, white, transparent);
    }
  }
  .actions {
    display: flex;
    justify-content: end;
    .btn {
      width: 20%;
      margin: 15px 5px 0 5px;
      padding: 10px 0;
      border-radius: 5px;
      &.error {
        border: 2px solid #da384b;
        background: transparent;
        color: #da384b;
        &:hover {
          background: #da384b;
          color: white;
        }
      }
      &.success {
        border: 2px solid #229861;
        background: transparent;
        color: #229861;
        &:hover {
          background: #229861;
          color: white;
        }
      }
    }
  }
}
</style>
