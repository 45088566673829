<template>
  <button :type="type" @click="$emit('click')" class="base-button lt-hover">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["type"],
};
</script>

<style lang="scss">
.base-button {
  width: 100%;
  padding: 7px 5px;
  border-radius: 3px;
  margin-bottom: 10px;
  transition: all 0.5s ease;
  color: #458d45;
  background: rgba(0, 0, 0, 0.574);
  border: 1px solid #458d45;
  &:hover,
  &:active {
    background: #458d45;
    color: white;
    border: 1px solid transparent;
  }
}
</style>
