import baseUrl from "../../plugins/axios/axios.js";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  /**
   *get all tournaments
   */
  getAllTournamets(_, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/tournaments/all", null, { params: params })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get user New tournaments
   */
  getNewTournamets(_, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/tournaments/new", { params: params })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get user Running tournaments
   */
  getRunningTournamets(_, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/front/tournaments/running", { params: params })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get active joined tournaments
   */
  getActiveJoinedTournamets() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/tournaments/own/active")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get finished joined tournaments
   */
  getFinishedJoinedTournamets() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/tournaments/own/finished")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get active joined tournaments score
   */
  getActiveJoinedScore(_, id) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/tournaments/own/get-score", { tournament_id: id })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *join to the tournament
   */
  JoinTournament(_, id) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/tournaments/${id}/join`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },

  /**
   *get tournament leaderboard
   */
  getTournamentLeaderboard(_, id) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/front/tournaments/${id}/leaderboards`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
