const state = {
  returnRoute: null,
};

const getters = {
  // Get Alerts
  returnRoute: (state) => state.returnRoute,
};

const mutations = {
  // Set Alert
  setReturnRoute(state, route) {
    state.returnRoute = route;
  },
};

const actions = {
  // Set returnRoute
  setReturnRoute({ commit }, route) {
    commit("setReturnRoute", route);
  },
  clearReturnRoute({ commit }) {
    commit("setReturnRoute", null);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
