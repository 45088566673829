<template>
  <div
    class="tr-details"
    :style="`${
      item.image
        ? `background:url(${imageLinkFormatter(item.image)})`
        : `background:url(${require('@/assets/images/running-card/card3.jpg')})`
    }`"
  >
    <h2 class="name">{{ item.name }}</h2>
    <h4 class="game-name">{{ item.game.name }}</h4>
    <p class="desc Q-font">{{ item.description }}</p>
    <Rewards :rewards="item.rewards"></Rewards>
    <h6 class="total-prizes">
      <div>Total Prizes</div>
      <span v-for="(value, name, i) in item.total_price" :key="i">
        {{ value }} {{ name }} <span v-if="i < 1">-</span>
      </span>
    </h6>
    <TopScores :top-scores="item.top_scores" class="top-scores"></TopScores>
    <section class="date-and-time">
      <div class="start">
        <p>{{ convertDate(item.start_time, "DMMMYYYY") }}</p>
        <p>{{ convertDate(item.start_time, "HH:mm") }}</p>
      </div>
      <span>-</span>
      <div class="finish">
        <p>{{ convertDate(item.finish_time, "DMMMYYYY") }}</p>
        <p>{{ convertDate(item.finish_time, "HH:mm") }}</p>
      </div>
    </section>

    <button
      @click="joinTournament(item)"
      :style="!jcShow ? 'margin-bottom:30px' : ''"
      :disabled="item.is_joined || !rTimeFlag"
    >
      <Loading v-if="!rTime" class="loading"></Loading>
      <div v-else>
        <div v-if="token">
          <span v-if="item.is_joined">Joined</span>
          <span v-else-if="!rTimeFlag">Finished</span>
          <span v-else>
            <span style="margin-right: 5px">join For</span>
            <span
              v-for="(v, n, i) in JSON.parse(item.entry_price)"
              :key="i"
              style="margin-right: 5px"
            >
              <span>{{ v.amount }}</span
              ><span style="font-size: 10px">{{ v.coin }}</span>
            </span>
          </span>
        </div>
        <span v-else>Login to join</span>
      </div>
    </button>
    <JoinConfirmation
      class="join-confirmation"
      v-if="jcShow"
      :item="item"
      @cancleJoin="jcShow = false"
      @joinedTrSuccessfully="joinedTrSuccessfully"
    ></JoinConfirmation>
  </div>
</template>

<script>
import {
  dateFormater,
  timeFormater,
  remainingTime,
} from "../../../mixins/calendarFormater.js";
import TopScores from "./TopScores.vue";
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      jcShow: false,
    };
  },
  mixins: [dateFormater, timeFormater, remainingTime],
  computed: {
    ...mapGetters(["token"]),
  },
  components: { TopScores },
  created() {
    this.remainingTime(this.item.finish_time); //this function is comming from mixin
  },
  methods: {
    joinTournament() {
      if (this.token) this.jcShow = !this.jcShow;
      else this.$router.push({ name: "login" });
    },
    joinedTrSuccessfully() {
      this.$emit("DetailJoinedTrSuccessfully");
    },
    imageLinkFormatter(img) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${img}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.tr-details {
  background-size: cover !important ;
  background-repeat: no-repeat !important;
  background-position: center !important;
  text-align: center;
  color: var(--color5);
  position: relative;
  z-index: 1;
  padding-top: 30px;
  // max-height: 60vh;
  // overflow: auto;
  @include sm {
    padding: 0 5px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.669);
    z-index: -1;
  }
  .name {
    color: var(--color7);
    text-shadow: 0 0 10px blue;
    span {
      color: white;
    }
  }
  .game-name {
    margin: 5px 0;
  }
  .desc {
    font-size: 14px;
  }
  .total-prizes {
    color: gold;
    text-align: center;
    margin-top: 5px;
  }
  .top-scores {
    margin: 10px 0;
    background: linear-gradient(
      to right,
      transparent,
      var(--color3),
      rgba(0, 183, 255, 0.542)
    );
  }
  .date-and-time {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    p {
      font-family: "Quantico", sans-serif;
      font-size: 14px;
      margin: 0;
    }
  }
  button {
    padding: 10px 0;
    width: 90%;
    margin-top: 20px;
    border-radius: 5px;
    background: green;
    color: white;
    // margin-bottom: 30px;
    &:disabled {
      background: grey;
      opacity: 0.8;
      cursor: unset;
    }
    .loading {
      &::v-deep div {
        padding: 5px 0 !important;
      }
    }
  }
  .join-confirmation {
    background: rgba(0, 0, 0, 0.932);
    text-align: start;
  }
}
</style>
