import { render, staticRenderFns } from "./TournamentLeaderboard.vue?vue&type=template&id=1eb78aae&scoped=true&"
import script from "./TournamentLeaderboard.vue?vue&type=script&lang=js&"
export * from "./TournamentLeaderboard.vue?vue&type=script&lang=js&"
import style0 from "./TournamentLeaderboard.vue?vue&type=style&index=0&id=1eb78aae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eb78aae",
  null
  
)

export default component.exports