<template>
  <li class="w-card">
    <slot></slot>
  </li>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.w-card {
  width: 98%;
  max-width: 17.5em;
  height: 23em;
  position: relative;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: -0.1875em;
    bottom: -0.1875em;
    right: -0.1875em;
    top: -0.1875em;
    background: linear-gradient(235deg, #fb00ff, transparent, #00e1ff);
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    z-index: -1;
  }
  &::after {
    filter: blur(15px);
    overflow: hidden;
  }
  &:nth-child(2n + 2) {
    &::before,
    &::after {
      background: linear-gradient(235deg, rgb(51, 255, 0), transparent, yellow);
    }
  }
}
</style>
