import baseUrl from "../../plugins/axios/axios.js";
const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
 

 
  /**
   *get all tickets
   */
   getAllTickets(_, params) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/tickets", {params:params})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *add ticket
   */
   addTicket(_, info) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post("/users/tickets",info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },
  /**
   *get messages of ticket
   */
   getMessagesTicket(_, id) {
    return new Promise((resolve, reject) => {
      baseUrl
        .get(`/users/tickets/messages/${id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  /**
   *send messages for ticket
   */
   sendMessageTicket(_, info) {
    return new Promise((resolve, reject) => {
      baseUrl
        .post(`/users/tickets/messages/${info.id}`,{message:info.message})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },

  
 
  
};

export default {
  state,
  getters,
  mutations,
  actions,
};
