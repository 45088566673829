import Vue from "vue";
import { defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/main.scss";
import Navigation from "./components/containers/navigation/Navigation.vue";
import Footer from "./components/containers/Footer.vue";
import BaseLoading from "./components/common/BaseLoading.vue";
import PageTitle from "./components/common/PageTitle.vue";
import MinigamesLogo from "./components/containers/navigation/MinigamesLogo";
import BorderRotatingButton from './components/common/BorderRotatingButton.vue'
import Sidebar from './components/containers/sidebar/Sidebar.vue'
import ThemeLine from './components/common/ThemeLine.vue'
import NeonButton from './components/common/NeonButton.vue'
import Input from './components/common/Input.vue'
import Textarea from './components/common/Textarea.vue'
import BaseModal from './components/common/BaseModal.vue'
import BaseSwitch from "./components/common/BaseSwitch.vue";
import PanelBaseButton from "./components/common/PanelBaseButton.vue";
import Loading from './components/common/Loading.vue'
import Pagination from './components/common/Pagination.vue'
import NoResult from "./components/common/NoResult.vue";
import DropDown from "./components/common/DropDown.vue";
import ConfirmationModal from "./components/common/ConfirmationModal.vue";
import Tooltip from "./components/common/Tooltip.vue";
import Rewards from './components/panelSections/tournaments/Rewards.vue'
import TournamentItem from './components/panelSections/tournaments/TournamentItem.vue'
import Or from './components/common/Or.vue'
import GoogleLogin from './views/auth/GoogleLogin.vue'
import TournamentDetails from "./components/LandingSections/runningTournamentsSection/TournamentDetails.vue";
import TournamentLeaderboard from "./components/panelSections/tournaments/leaderboard/TournamentLeaderboard.vue";
import JoinConfirmation from './components/panelSections/tournaments/JoinConfirmation.vue'



Vue.config.productionTip = false;
Vue.component("Navigation", Navigation);
Vue.component("Footer", Footer);
Vue.component("Sidebar", Sidebar);
Vue.component("ThemeLine", ThemeLine);
Vue.component("NeonButton", NeonButton);
Vue.component("BorderRotatingButton", BorderRotatingButton);
Vue.component("BaseModal", BaseModal);
Vue.component("BaseSwitch", BaseSwitch);
Vue.component("PanelBaseButton", PanelBaseButton);
Vue.component("Input", Input);
Vue.component("Textarea", Textarea);
Vue.component("Loading", Loading);
Vue.component("Pagination", Pagination);
Vue.component("BaseLoading", BaseLoading);
Vue.component("NoResult", NoResult);
Vue.component("PageTitle", PageTitle);
Vue.component("DropDown", DropDown);
Vue.component("ConfirmationModal", ConfirmationModal);
Vue.component("Tooltip", Tooltip);
Vue.component("Rewards", Rewards);
Vue.component("TournamentItem", TournamentItem);
Vue.component("Or", Or);
Vue.component("MinigamesLogo", MinigamesLogo);
Vue.component("GoogleLogin", GoogleLogin);
Vue.component("TournamentDetails", TournamentDetails);
Vue.component("TournamentLeaderboard", TournamentLeaderboard);
Vue.component("JoinConfirmation", JoinConfirmation);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
