<template>
  <nav class="nav">
    <div class="container">
      <div class="m-drawer animate child-4">
        <!-- <i class="bx bx-menu" @click="toggleDrawer"></i> -->
        <!-- <router-link :to="{name:'landing'}">logo</router-link> -->
        <!-- <router-link
          :to="{ name: 'dashboard' }"
          class="mobile-menu-item"
          v-if="token && $route.name === 'landing'"
          >Dashboard</router-link
        >
        <router-link :to="{ name: 'landing' }" class="mobile-menu-item" v-else
          >Game center</router-link
        > -->
      </div>
      <MinigamesLogo @click.native="goToLanding"></MinigamesLogo>
      <ul class="menu-items">
        <li class="menu-item animate child-2" v-if="token && $route.name === 'landing'">
          <router-link :to="{ name: 'dashboard' }" class="">Dashboard</router-link>
        </li>
        <li class="menu-item animate child-3">
          <a href="/#running-tournaments">Running Tournaments</a>
        </li>
        <li class="menu-item animate child-4">
          <a href="/#top-winners">Top Winners</a>
        </li>
      </ul>

      <div class="actions">
        <!-- <neon-button>Register</neon-button> -->
        <div class="user-info animate child-5" v-if="user">
          <Avatar v-if="$route.name === 'landing'"></Avatar>
          <!-- <Notify></Notify> -->
        </div>
        <BorderRotatingButton
          @click="userLogout"
          class="animate"
          style="margin-left: 10px"
          v-if="user && $route.name !== 'landing'"
          >Logout</BorderRotatingButton
        >
        <BorderRotatingButton
          @click="goToLoginPage"
          class="animate child-5"
          id="login-btn"
          v-else-if="!user"
          >Login</BorderRotatingButton
        >
      </div>
    </div>
    <!-- <MobileDrawer
      v-if="mobailDrawer"
      @close="mobailDrawer = false"
    ></MobileDrawer> -->
  </nav>
</template>

<script>
import Avatar from "./Avatar.vue";
// import Notify from "./Notify.vue";
// import MobileDrawer from "./MobileDrawer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      mobailDrawer: false,
    };
  },
  components: {
    // Notify,
    Avatar,
    // MobileDrawer,
  },
  mounted() {
    const navItems = document.querySelectorAll(".animate");
    navItems.forEach((element) => {
      element.classList.add("slide-down");
    });
    this.navbarAnimation();
  },
  computed: {
    ...mapGetters(["token", "user"]),
  },
  watch: {},
  methods: {
    ...mapActions(["logout"]),
    goToLoginPage() {
      this.$router.push({ name: "login" });
    },
    goToLanding() {
      this.$router.push({ name: "landing" }).catch(() => {});
    },
    userLogout() {
      this.logout();
      if (this.$route.name !== "landing") this.$router.replace({ name: "landing" });
    },
    toggleDrawer() {
      this.mobailDrawer = !this.mobailDrawer;
    },
    navbarAnimation() {
      const observeObject = document.querySelector(".observer-object");
      const navbar = document.querySelector(".nav");
      let navObserver = new IntersectionObserver(
        (enteries) => {
          enteries.forEach((entery) => {
            if (entery.isIntersecting) {
              //if object is exsist in view port and threshold is 0
              navbar.classList.remove("watched");
            } else {
              navbar.classList.add("watched");
            }
          });
        },
        { threshold: 0 }
      );
      navObserver.observe(observeObject);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.nav {
  position: fixed;
  top: 0;
  height: 90px;
  width: 100%;
  transition: all 0.6s ease;
  z-index: 100;
  &.watched {
    transition: all 0.6s ease;
    // background: linear-gradient(to right, var(--color7), var(--color2));
    background: var(--color3);
    height: 70px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  .container {
    // padding: 0 40px;
    display: flex;
    align-items: center;

    .m-drawer {
      display: none;
      @include md {
        display: unset;
      }
      .mobile-menu-item {
        font-size: 15px;
        color: white;
      }
      // .bx-menu {
      //   font-size: 40px;
      //   color: white;
      //   cursor: pointer;
      // }
    }
    .menu-items {
      display: flex;
      margin-left: 60px;
      @include md {
        display: none;
      }
      .menu-item {
        margin-right: 18px;
        font-size: 14px;
        cursor: pointer;
        a {
          color: var(--color5);
        }
        &:hover {
          text-shadow: 0 0 5px white;
        }
      }
    }
    .menu-items-mobile {
      display: none;
      margin-left: 20px;
      @include md {
        display: flex;
      }
      .menu-item-mobile {
        font-size: 12px !important;
        cursor: pointer;
        color: white;
      }
    }
    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      .user-info {
        display: flex;
        align-items: center;
      }
    }
  }
}
.slide-down {
  animation: moveDown 1s ease forwards;
  &.child-2 {
    animation-delay: 0.2s;
  }
  &.child-3 {
    animation-delay: 0.4s;
  }
  &.child-4 {
    animation-delay: 0.6s;
  }
  &.child-5 {
    animation-delay: 0.8s;
  }
  &.child-6 {
    animation-delay: 1s;
  }
  &.child-7 {
    animation-delay: 1.2s;
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate {
  opacity: 0;
}
</style>
