<template>
  <li class="">
    <button  class="item" @click="itemClicked" :class="{active: $route.name === item.name}">
      <i :class="`bx bxs-${item.icon}`"></i>
      <h3 class="text">{{ item.text }}</h3>
    </button>
  </li>
</template>

<script>
export default {
  props: ["item"],
  methods:{
    itemClicked(){
      this.$router.push({name : this.item.name}).catch(()=>{})
      if(window.innerWidth < 860)
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.item {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
  background: none;
  width: 100%;
  // @include md{
  //   margin-left: -10px;
  // }
  &:hover {
    background: grey;
  }
  .text {
    color: black;
    width: 0;
    transition: all 0.5s ease;
    font-size: 15px;
    margin-left: 15px;
    white-space: nowrap;
  }
  .bx {
    font-size: 25px;
    color: black;
    margin-right: 8px;
    padding: 10px;
  }
  &.active {
    transition: all 0.5s ease;
    background: var(--color7) !important;
    box-shadow: 0 10px 10px black;
  }
}
</style>
