import mainApi from "../../plugins/axios/axios";
export default {
  state: { uploadEvent: null },
  mutations: {
    setUploadEvent(state, payload) {
      state.uploadEvent = payload;
    },
  },
  actions: {
    uploadImage({ commit }, info) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(info.link, info.formData, {
            onUploadProgress: (uploadEvent) => {
              commit("setUploadEvent", uploadEvent);
            },
          })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            
            reject();
          })
          .finally(() => {
            commit("setUploadEvent", null);
          });
      });
    },
  },
  getters: {
    uploadEvent(state) {
      return state.uploadEvent;
    },
  },
};
