<template>
  <div class="rewards">
    <i class="silver bx bxs-medal">
      <p v-for="(silverReward, i) in formatedRewards[1]" :key="i">
        <span v-if="silverReward.type === 'percent'">
          <h4 v-if="silverReward.cPR">
            <span class="Q-font">{{ silverReward.cPR }}</span>
            <span class="Q-font">{{ silverReward.coin }}</span>
          </h4>
          <h4 v-else>
            <span class="Q-font">{{ silverReward.amount }}</span>
            <span class="Q-font">% All {{ silverReward.coin }}</span>
          </h4>
        </span>
        <span v-else>
          <span class="Q-font">{{ silverReward.amount }}</span>
          <span class="Q-font">{{ silverReward.coin }}</span>
        </span>
      </p>
    </i>
    <i class="gold bx bxs-trophy">
      <p v-for="(goldReward, i) in formatedRewards[0]" :key="i">
        <span v-if="goldReward.type === 'percent'">
          <h4 v-if="goldReward.cPR">
            <span class="Q-font">{{ goldReward.cPR }}</span>
            <span class="Q-font">{{ goldReward.coin }}</span>
          </h4>
          <h4 v-else>
            <span class="Q-font">{{ goldReward.amount }}</span>
            <span class="Q-font">% all {{ goldReward.coin }}</span>
          </h4>
        </span>
        <span v-else>
          <span class="Q-font">{{ goldReward.amount }}</span>
          <span class="Q-font">{{ goldReward.coin }}</span>
        </span>
      </p>
    </i>
    <i class="bronze bx bx-medal">
      <p v-for="(bronzeReward, i) in formatedRewards[2]" :key="i">
        <span v-if="bronzeReward.type === 'percent'">
          <h4 v-if="bronzeReward.cPR">
            <span class="Q-font">{{ bronzeReward.cPR }}</span>
            <span class="Q-font">{{ bronzeReward.coin }}</span>
          </h4>
          <h4 v-else>
            <span class="Q-font">{{ bronzeReward.amount }}</span>
            <span class="Q-font">% all {{ bronzeReward.coin }}</span>
          </h4>
        </span>
        <span v-else>
          <span class="Q-font">{{ bronzeReward.amount }}</span>
          <span class="Q-font">{{ bronzeReward.coin }}</span>
        </span>
      </p>
    </i>
  </div>
</template>

<script>
export default {
  props: ["rewards", "totalPrice"],
  data() {
    return {
      formatedRewards: [],
    };
  },
  created() {
    this.formatRewards();
  },
  methods: {
    formatRewards() {
      this.formatedRewards = this.rewards ? Object.values(JSON.parse(this.rewards)) : [];
      this.formatedRewards.forEach((element) => {
        this.decomposeReward(element);
      });

      console.log(this.formatedRewards);
    },
    decomposeReward(playerRewards) {
      playerRewards.forEach((element) => {
        if (element.type === "percent" && this.totalPrice) {
          const precentage = element.amount; //if type percent then amount giving us number of percentage
          if (element.coin === "ecg") {
            const calculatedPercentReward = (precentage / 100) * this.totalPrice.ECG;
            element.cPR = calculatedPercentReward;
          } else {
            const calculatedPercentReward = (precentage / 100) * this.totalPrice.USDT;
            element.cPR = calculatedPercentReward;
          }
        } else {
          element.cPR = null;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.rewards {
  display: flex;
  justify-content: center;
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 14px;
  }
  i {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0.2125rem;
    font-size: 2.1875em;
    p {
      font-size: 0.265em;
      font-weight: 700;
    }
  }
  .gold {
    color: gold;
  }
  .silver {
    color: silver;
  }
  .bronze {
    color: #cd7f32;
  }
}
</style>
