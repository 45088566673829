<template>
  <div class="footer">
    <div class="footer-wrapper container">
      <section class="logo">
        <MinigamesLogo
          @click.native="goToLanding"
          class="mini-logo observe-item"
        ></MinigamesLogo>
        <p class="Q-font observe-item">
          EcoSmart GameCenter has it's unique approach to use smart contracts in
          blockchain gaming.
        </p>
        <p class="Q-font observe-item">[ EcoSmart mini-games ]</p>
        <ul>
          <li class="Q-font observe-item observe-1">Leaderboard match style</li>
          <li class="Q-font observe-item observe-2">Two Player match style</li>
          <li class="Q-font observe-item observe-3">multi-chain</li>
          <li class="Q-font observe-item observe-4">
            Along with the P2E and N.M Plan There will be many sections and new games
            which will be available once or more in a while.
          </li>
        </ul>
        <p class="Q-font observe-item observe-5">
          There will be many sections and new games which will be available once or more
          in a while.
        </p>
      </section>
      <section class="contact">
        <h3 class="observe-item">Contact</h3>
        <ul>
          <li v-for="(item, i) in contacts" :key="i" :class="`observe-item observe-${i}`">
            <i :class="`bx ${item.icon}`"></i>
            <p class="Q-font">{{ item.text }}</p>
          </li>
        </ul>
        <li>
          <router-link :to="{ name: 'terms-and-conditions' }">
            Terms and conditions
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'privacy-policy' }" style="margin-top: -40px">
            Privacy Policy
          </router-link>
        </li>
      </section>
      <section class="follow-us">
        <h3 class="observe-item">Follow Us</h3>
        <ul>
          <li class="observe-item">
            <a href="twitter.com/EcoSmartECS"><i class="bx bxl-twitter"></i></a>
          </li>
          <li class="observe-item observe-1">
            <a href="instagram.com/EcoSmartECS">
              <i class="bx bxl-instagram-alt"></i>
            </a>
          </li>
          <li class="observe-item observe-2">
            <a href="t.me/EcoSmartECS">
              <i class="bx bxl-telegram"></i>
            </a>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contacts: [{ icon: "bxs-envelope", text: "info@EcoSmartECS.com" }],
    };
  },
  methods: {
    goToLanding() {
      this.$router.push({ name: "landing" }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.footer {
  min-height: 15.625em;
  // background: url('../assets/images/footer/footer--bg.png') no-repeat;
  background-color: rgb(17, 17, 17);
  background-size: 100%;
  padding: 2.5em 2.1875em;
  // overflow: hidden;
  .footer-wrapper {
    color: white;
    display: grid;
    grid-gap: 0.625em;
    grid-template-areas: "logo logo contact follow ";

    .logo {
      grid-area: logo;
      .mini-logo {
        font-size: 9px !important;
      }
      p {
        font-size: 11px;
      }
      ul {
        font-size: 11px;
        li {
          list-style: outside;
        }
      }
    }
    .contact {
      grid-area: contact;

      ul {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include sm {
          height: 80%;
        }
        li {
          display: flex;
          margin-bottom: 0.625em;
          i {
            margin-right: 0.3125em;
            font-size: 1.25em;
          }
          p {
            font-size: 0.875em;
          }
        }
      }
    }
    .follow-us {
      grid-area: follow;
      h3 {
        @include sm {
          display: none;
        }
      }
      ul {
        display: flex;
        @include sm {
          display: unset;
        }
        li {
          i {
            font-size: 1.875em;
            margin-right: 0.25em;
          }
          &.observe-4 {
            transition-delay: 0.8s;
          }
        }
      }
    }
    @include md {
      grid-template-areas:
        "logo logo logo logo"
        "contact contact contact follow";
      font-size: 13px;
    }
    @include sm {
      font-size: 11px;
      padding: 0;
    }
  }
}
h1,
h3 {
  margin-bottom: 0.625em;
}

.observe-item {
  transform: translateY(40px);
}
</style>
