var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-crousel"},[_c('div',{staticClass:"carousel-items observe-item"},_vm._l((_vm.slides),function(slide,i){return _c('div',{key:i,staticClass:"carousel-item",class:`${
        i === _vm.current
          ? 'current'
          : i === _vm.prev
          ? 'prev'
          : i === _vm.next
          ? 'next'
          : i === _vm.bPrev
          ? 'before-prev'
          : i === _vm.aNext
          ? 'after-next'
          : ''
      }`,on:{"click":function($event){return _vm.click(slide, i)}}},[(!slide.image)?_c('img',{staticClass:"image",attrs:{"src":require("@/assets/images/running-card/card2.jpg"),"alt":"image"}}):_c('img',{staticClass:"image",attrs:{"src":_vm.imageLinkFormatter(slide.image),"alt":"image"}}),_c('img',{staticClass:"tr-logo",attrs:{"src":require("@/assets/images/running-card/tournametsLogo1.png"),"alt":"","width":"55%"}}),_c('TimeRemainingLable',{attrs:{"item":slide,"index":i,"current":_vm.current}}),(_vm.token)?_c('button',{staticClass:"tr-join-btn lt-hover",class:i === _vm.current ? 'animation' : '',attrs:{"disabled":slide.is_joined},on:{"click":function($event){$event.stopPropagation();return _vm.joinTournament(slide)}}},[(slide.is_joined)?_c('section',{staticStyle:{"color":"orange"}},[_vm._v("Joined")]):_c('section',[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("join For")]),_vm._l((JSON.parse(slide.entry_price)),function(v,n,i){return _c('span',{key:i,staticStyle:{"margin-right":"5px"}},[_c('span',[_vm._v(_vm._s(v.amount))]),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(v.coin))])])})],2)]):_c('button',{staticClass:"tr-join-btn lt-hover",class:i === _vm.current ? 'animation' : ''},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("join Now")])]),_c('div',{staticClass:"information-section"},[_c('div',{staticClass:"information-wrapper"},[_c('div',{staticClass:"information-content"},[_c('h3',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(slide.name))]),_c('Rewards',{attrs:{"rewards":slide.rewards}}),_c('h4',[_vm._v(_vm._s(slide.game.name))])],1)])])],1)}),0),_c('BaseModal',{attrs:{"dialog":_vm.joinConfirmationDialog,"title":"join Tournament confirm"},on:{"close":function($event){_vm.joinConfirmationDialog = false}}},[_c('JoinConfirmation',{attrs:{"item":_vm.itemSelected},on:{"cancleJoin":function($event){_vm.joinConfirmationDialog = false},"joinedTrSuccessfully":_vm.joinedTrSuccessfully}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }