// import store from "../store/index.js";

export default (to, from, next) => {
  if (
    localStorage.getItem("user") != null &&
    localStorage.getItem("user") != undefined &&
    localStorage.getItem("user").length > 0 &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token").length > 0 
  ) {
    next();
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    next("/");
  }
};
