<template>
  <div v-if="landingIsLoading">
    <base-loading style="height: 100vh"></base-loading>
  </div>
  <div class="landing" v-else>
    <Navigation></Navigation>
    <Banner></Banner>
    <FrontRunningTournaments></FrontRunningTournaments>
    <TopWinners></TopWinners>
    <!-- <ScrollToTop></ScrollToTop> -->
    <Footer></Footer>
  </div>
</template>

<script>
// import ScrollToTop from "../components/LandingSections/back_to_top/ScrollToTop.vue";
import Banner from "../components/LandingSections/bannerSection/Banner.vue";
import FrontRunningTournaments from "../components/LandingSections/runningTournamentsSection/FrontRunningTournaments.vue";
import TopWinners from "../components/LandingSections/TopWinnersSection/TopWinners.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "landing",
  data() {
    return {
      landingIsLoading: false,
    };
  },
  components: {
    // ScrollToTop,
    Banner,
    FrontRunningTournaments,
    TopWinners,
  },
  computed: {
    ...mapGetters(["slides", "topWinners", "FRTournaments"]),
  },
  created() {
    let params = new URLSearchParams({ per_page: 100 });
    this.landingIsLoading = true;
    this.getGames().then(() => {
      this.getFrontRunningTournamets(params).then(() => {
        this.getFrontTopWinners()
          .then(() => {
            if (this.$route.hash) window.location.href = this.$route.hash;
          })
          .finally(() => {
            this.landingIsLoading = false;
          });
      });
    });
  },

  methods: {
    ...mapActions(["getGames", "getFrontTopWinners", "getFrontRunningTournamets"]),
  },
};
</script>

<style lang="scss" scoped>
.landing {
  // height: 500vh;
  &::before {
    content: "";
    width: 35%;
    height: 35%;
    border-radius: 100%;
    position: fixed;
    top: 27.5%;
    left: 18.9%;
    background-color: var(--color2);
    -webkit-filter: blur(150px);
    -o-filter: blur(150px);
    filter: blur(200px);
  }
}
</style>
