<template>
  <section class="banner-section" id="banner">
    <OneItemSlider></OneItemSlider>
  </section>
</template>

<script>
import OneItemSlider from "./OneItemSlider.vue";
export default {
  data() {
    return {
      
    };
  },
  mounted(){
    this.observeAnimation()
  },
  methods:{
    observeAnimation() {
      const observeItems = document.getElementsByClassName("observe-item");
      let observe = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("show");
          }
        });
      });
      for (let i = 0; i < observeItems.length; i++) {
        observe.observe(observeItems[i]);
      }
    },
    
  },
  components: {
    OneItemSlider,
  },
  
};
</script>

<style lang="scss" scoped>

.banner-section {
  padding-top: 100px;
  // background-image: radial-gradient(circle at bottom left,
  // rgb(29, 8, 8) 25%, #322c6c 70%);
}
 
</style>
