<template>
  <div class="spinner" >
  <div class="rect1" :style="`background : ${color}`"></div>
  <div class="rect2" :style="`background : ${color}`"></div>
  <div class="rect3" :style="`background : ${color}`"></div>
  <div class="rect4" :style="`background : ${color}`"></div>
  <div class="rect5" :style="`background : ${color}`"></div>
</div>
</template>

<script>
export default {
  props:['color'],
data(){
  return{

  }
}
}
</script>

<style lang="scss" scoped>
.spinner {
  // height: 100%;
  text-align: center;
  min-width: 20px;
}

.spinner > div {
  background: rgb(255, 255, 255);
  height: 15px;
  width: 2px;
  border-radius: 10px;
  display: inline-block; 
  margin: 0 1px;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
</style>