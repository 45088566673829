<template>
  <div class="confirmation-modal">
    <p>{{ message }}</p>
    <div class="line"></div>
    <div class="actions">
      <button class="btn error" @click="$emit('cancel')">{{ cancelBtnText }}</button>
      <button class="btn success" @click="$emit('accept')">
        <Loading v-if="confirmLoading"></Loading>
        <span v-else>{{ acceptBtnText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { scrollUnlock } from "@/mixins/scrollControl.js";
export default {
  mixins: [scrollUnlock],
  props: ["message", "confirmLoading", "cancelBtnText", "acceptBtnText"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.confirmation-modal {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  max-height: 80vh;
  overflow: auto;
  color: rgb(185, 185, 185);
  .line {
    height: 1px;
    margin: 5px 0;
    background: linear-gradient(to right, transparent, white, transparent);
  }
  .actions {
    display: flex;
    justify-content: end;
    .btn {
      width: 20%;
      margin: 15px 5px 0 5px;
      padding: 10px 0;
      border-radius: 5px;
      &.error {
        border: 2px solid #da384b;
        background: transparent;
        color: #da384b;
        &:hover {
          background: #da384b;
          color: white;
        }
      }
      &.success {
        border: 2px solid #229861;
        background: transparent;
        color: #229861;
        &:hover {
          background: #229861;
          color: white;
        }
      }
    }
  }
}
</style>
