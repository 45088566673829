export const swipe = {
  methods: {
    swipe(element, direction, exFunction) {
      //(element:that ele we want swipe on that),(direction:swip direction),(exFunction:that function we want execute on swip event)
      element.addEventListener("touchstart", handleTouchStart, false);
      element.addEventListener("touchmove", handleTouchMove, false);

      var xDown = null;
      var yDown = null;

      function handleTouchStart(event) {
        xDown = event.touches[0].clientX;
        yDown = event.touches[0].clientY;
      }

      function handleTouchMove(event) {
        if (!xDown || !yDown) {
          return;
        }

        var xUp = event.touches[0].clientX;
        var yUp = event.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /*most significant*/
          if (xDiff > 0 ) {
            /* left swipe */
            if(direction === 'left')
            exFunction();
          } else {
            /* right swipe */
            if(direction === 'right')
            exFunction();
          }
        } else {
          if (yDiff > 0) {
            if(direction === 'up')
            exFunction();
            /* up swipe */
          } else {
            if(direction === 'down')
            exFunction();
            /* down swipe */
          }
        }
      }
    },
  },
};
