<template>
  <section class="running-tournaments container" id="running-tournaments">
    <div class="tr-point"></div>
    <PageTitle title="Running Tournametns" background="tournaments"></PageTitle>
    <MultiItemSlider
      :slides="slides"
      :current="current"
      :prev="prev"
      :next="next"
      :bPrev="bPrev"
      :aNext="aNext"
      @clicked="clicked"
      @PauseInterval="PauseInterval"
      @resumeInterval="resumeInterval"
    >
    </MultiItemSlider>
    <div class="actions observe-item">
      <i class="ac-btn bx bxs-chevron-left bx-fade-left" @click="prevSlide"></i>
      <!-- <hr style="width: 100px; background-color: white" /> -->
      <ul class="points">
        <li
          v-for="(item, i) in slides"
          :key="i"
          class="point"
          :class="i === current ? 'active' : ''"
        ></li>
      </ul>
      <i class="ac-btn bx bxs-chevron-right bx-fade-right" @click="nextSlide"></i>
    </div>

    <ThemeLine class="tr-line observe-item"></ThemeLine>

    <!-- snigle Tournament modal -->
    <BaseModal
      :dialog="detailTournamentDialog"
      @close="closeModal"
      title="Tournament Details"
    >
      <TournamentDetails
        :item="selectedTournament"
        @DetailJoinedTrSuccessfully="DetailJoinedTrSuccessfully"
      ></TournamentDetails>
    </BaseModal>
    <!-- snigle Tournament modal -->
  </section>
</template>

<script>
import MultiItemSlider from "./MultiItemSlider.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Landing",
  data() {
    return {
      slides: [],
      slidesImages: [
        {
          name: "Tournametn 1",
          // image: "https://images.freeimages.com/images/large-previews/382/sun-girl-1377414.jpg",
          image: "card1.jpg",
        },
        {
          name: "Tournametn 2",
          // image: "https://images.freeimages.com/images/large-previews/70b/bike-red-1423575.jpg",
          image: "card2.jpg",
        },
        {
          name: "Tournametn 3",
          // image: "https://images.freeimages.com/images/large-previews/d0f/nuclear-power-plant-1314782.jpg",
          image: "card3.jpg",
        },
        {
          name: "Tournametn 4",
          // image: "https://images.freeimages.com/images/large-previews/e22/bike-in-sunset-2-1550297.jpg",
          image: "card4.jpg",
        },
        {
          name: "Tournametn 5",
          // image: "https://images.freeimages.com/images/large-previews/1b4/car-1555101.jpg",
          image: "card5.jpg",
        },
        {
          name: "Tournametn 6",
          // image: "https://images.freeimages.com/images/large-previews/f99/red-photos-1198380.jpg",
          image: "card6.jpg",
        },
      ],

      bPrev: 0,
      prev: 1,
      current: 2,
      next: 3,
      aNext: 4,

      interval: null,

      detailTournamentDialog: false,
      selectedTournament: null,
    };
  },
  components: {
    MultiItemSlider,
  },
  computed: {
    ...mapGetters(["FRTournaments"]),
  },
  mounted() {
    this.handleLocalImages();
    this.startInterval();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    handleLocalImages() {
      this.slides = this.FRTournaments.map((element, i) => {
        let localImage = this.slidesImages[i] ? this.slidesImages[i].image : null;
        if (localImage) return { ...element, localImage };
        else return element;
      });
    },
    clicked(slide, index) {
      if (index === this.prev) this.prevSlide();
      else if (index === this.next) this.nextSlide();
      else if (index === this.current) {
        this.detailTournamentDialog = true;
        clearInterval(this.interval);
        this.selectedTournament = slide;
      }
    },
    startInterval() {
      this.interval = setInterval(() => {
        this.prevSlide();
      }, 6000);
    },

    closeModal() {
      this.detailTournamentDialog = false;
      this.startInterval();
    },
    PauseInterval() {
      clearInterval(this.interval);
    },
    resumeInterval() {
      this.startInterval();
    },
    DetailJoinedTrSuccessfully() {
      this.detailTournamentDialog = false;
      this.startInterval();
      // this.handleLocalImages();
      // this.startInterval();
    },
    nextSlide() {
      clearInterval(this.interval);
      if (this.current === this.slides.length - 1) {
        this.current = 0;
        this.prev = this.slides.length - 1;
        this.bPrev = this.slides.length - 2;
        this.next = 1;
        this.aNext = 2;
      } else if (this.current === this.slides.length - 2) {
        this.current = this.slides.length - 1;
        this.prev = this.slides.length - 2;
        this.bPrev = this.slides.length - 3;
        this.next = 0;
        this.aNext = 1;
      } else if (this.current === this.slides.length - 3) {
        this.current = this.slides.length - 2;
        this.prev = this.slides.length - 3;
        this.bPrev = this.slides.length - 4;
        this.next = this.slides.length - 1;
        this.aNext = 0;
      } else if (this.current === 0) {
        this.current++;
        this.bPrev = this.slides.length - 1;
        this.prev = 0;
        this.next++;
        this.aNext++;
      } else if (this.current === 1) {
        this.current++;
        this.bPrev = 0;
        this.prev = 1;
        this.next++;
        this.aNext++;
      } else {
        this.current++;
        this.bPrev++;
        this.prev++;
        this.next++;
        this.aNext++;
      }
      this.startInterval();
    },
    prevSlide() {
      clearInterval(this.interval);
      if (this.current === 0) {
        this.current = this.slides.length - 1;
        this.prev = this.slides.length - 2;
        this.bPrev = this.slides.length - 3;
        this.next = 0;
        this.aNext = 1;
      } else if (this.current === 1) {
        this.current = 0;
        this.prev = this.slides.length - 1;
        this.bPrev = this.slides.length - 2;
        this.next = 1;
        this.aNext = 2;
      } else if (this.current === 2) {
        this.current = 1;
        this.prev = 0;
        this.bPrev = this.slides.length - 1;
        this.next = 2;
        this.aNext = 3;
      } else if (this.current === this.slides.length - 1) {
        this.current--;
        this.next = this.slides.length - 1;
        this.aNext = 0;
        this.prev--;
        this.bPrev--;
      } else if (this.current === this.slides.length - 2) {
        this.current--;
        this.next = this.slides.length - 2;
        this.aNext = this.slides.length - 1;
        this.prev--;
        this.bPrev--;
      } else {
        this.next--;
        this.aNext--;
        this.prev--;
        this.bPrev--;
        this.current--;
      }
      this.startInterval();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_sliders.scss";
@import "@/assets/style/_mixins.scss";
.running-tournaments {
  // min-height: 120vh;
  width: 100%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // background-image: radial-gradient(
  //   circle at top left,
  //   rgb(29, 8, 8) 25%,
  //   #322c6c 70%
  // );
  @include sm {
    padding: 0;
  }
  .actions {
    display: flex;
    align-items: center;
    margin: 0 auto;
    i {
      border: 2px solid white;
      color: white;
      padding: 10px;
      font-size: 25px;
      border-radius: 50%;
      margin: 0 20px;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover,
      &:active {
        background: var(--color7);
        color: black;
        box-shadow: 0 10px 10px rgb(0, 0, 0);
        border-color: transparent;
      }

      // @include md {
      //   font-size: 19px;
      // }
      @include sm {
        font-size: 12px;
        margin: 0;
      }
    }
  }
  .tr-line {
    margin-top: 100px;
    @include sm {
      margin-top: 30px;
    }
  }
  .no-item {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: grey;
      font-size: 20px;
    }
  }
}
</style>
