<template>
  <div class="wrapper">
    <div
      class="drop-down-header"
      :class="[
        `${show ? 'show' : ''}`,
        `${selected ? 'selected' : ''}`,
        `${errorMessage ? 'error' : ''}`,
      ]"
    >
      <div class="content" @click="toggleDropDown">
        <div class="loading" v-if="isLoading">
          <span style="margin-right: 15px">loading list </span>
          <Loading></Loading>
        </div>
        <div v-else-if="!selected">{{ placeHolder }}</div>
        <span class="header-text" v-else>
          <img
            v-if="iconKey"
            :src="selectedIcon"
            alt="iconKey"
            class="icon-image"
          />
          {{ selected }}
        </span>
        <div>
          <i
            class="bx bx-x"
            style="color: white"
            v-if="selected && !disabled"
            @click.stop="clearAll"
          ></i>
          <i
            class="bx bxs-chevron-down"
            :style="show ? 'transform:rotate(180deg)' : ''"
          ></i>
        </div>
      </div>

      <!-- expansion-start -->
      <div class="expansion" :class="show ? 'show' : ''">
        <div class="expansion-body">
          <label
            v-for="(item, i) in selectableItems"
            :key="i"
            class="selectable-items"
            :for="item.name"
            :style="
              item.name === selected
                ? 'background:black;color:var(--color7)'
                : ''
            "
            ><input
              type="radio"
              :name="item.name"
              :id="item.name"
              @change="updateValue(item)"
              :checked="item.value ? value == item.value : value == item.id"
              :value="item.value ? item.value : item.id"
              :disabled="disabled"
            />
            <span class="item-holder">
              <img
                v-if="iconKey"
                :src="item[iconKey]"
                alt="iconKey"
                class="icon-image"
              />
              <span style="margin-right: 5px">{{ item.name }}</span>
              <span style="color: var(--color1)" v-if="secondName"
                >({{ item[secondName] }})</span
              >
            </span>
          </label>
        </div>
      </div>
      <!-- expansion-end -->
      <p class="error-message" v-if="errorMessage">{{ this.errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "selectableItems",
    "isLoading",
    "placeHolder",
    "required",
    "checkValidationFlag",
    "value",
    "disabled",
    "secondName",
    "iconKey",
  ],
  data() {
    return {
      show: false,
      // selectableItems: [
      //   { name: "lowest", value: "lowest" },
      //   { name: "highest", value: "highest" },
      //   { name: "prettiest", value: "prettiest" },
      //   { name: "chipest", value: "chipest" },
      //   { name: "shittest", value: "shittest" },
      // ],
      selected: null,
      errorMessage: "",
    };
  },
  watch: {
    selectableItems(val) {
      if (this.value) {
        this.selected = val.find((ele) => ele.id === this.value).name;
        this.requiredValidation();
      }
      if (val.length === 1) {
        this.selected = val[0].name;
      }
    },
    checkValidationFlag(v) {
      if (v === true) {
        this.requiredValidation();
      }
    },
  },
  computed: {
    selectedIcon() {
      return this.selectableItems.find((item) => item.name === this.selected)[
        this.iconKey
      ];
    },
  },
  mounted() {
    // this.requiredValidation();
    console.log(this.selectableItems);
  },
  methods: {
    updateValue(item) {
      this.$emit("input", item.value ? item.value : item.id); //this line so neccessery(v-model working on this component because of this line )
      this.selected = item.name;
      this.requiredValidation();

      this.toggleDropDown();
    },
    toggleDropDown() {
      if (!this.isLoading) this.show = !this.show;
      const expansion = document.querySelector(".expansion");
      if (!this.show) {
        expansion.style.height = "0px";
      } else {
        // expansion.style.height = expansion.scrollHeight + "px";
        expansion.scrollHeight > 142
          ? (expansion.style.cssText = `height:${expansion.scrollHeight}px; overflow:auto`)
          : (expansion.style.cssText = `height:${expansion.scrollHeight}px;overflow:hidden`);
      }
    },
    requiredValidation() {
      if (this.required) {
        if (!this.selected) {
          this.errorMessage = "*This field is required.";
        } else {
          this.errorMessage = "";
        }
      }
      this.$emit("isValid", !!this.selected);
    },
    clearAll() {
      this.$emit("input", null);
      this.selected = null;
      this.requiredValidation();
      this.$emit("isValid", !!this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .drop-down-header {
    position: relative;
    border: 2px solid white;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.2rem;
    transition: all 0.4s ease;
    cursor: pointer;
    &.show {
      box-shadow: 0 0 10px var(--color7);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-color: var(--color7);
    }
    &.selected {
      box-shadow: 0 0 10px var(--color7);
      border-color: var(--color7);
      .header-text {
        color: var(--color7);
        display: flex;
        align-items: center;
      }
      .content {
        i {
          color: var(--color7);
        }
      }
    }
    &.error {
      box-shadow: 0 0 10px red;
      border-color: red;
      .expansion {
        &.show {
          border: 2px solid red;
          box-shadow: 0 5px 10px red;
        }
      }
    }
    .content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      color: white;
      i {
        font-size: 20px;
        transition: all 0.5s ease;
      }
      .loading {
        display: flex;
        align-items: center;
      }
    }
    .expansion {
      height: 0px;
      max-height: 142px;
      position: absolute;
      left: -2px;
      right: -2px;
      border-color: transparent;
      user-select: none;
      transition: all 0.4s ease;
      border: 0px solid white;
      overflow: hidden;
      z-index: 1000;
      box-shadow: 0 3px 10px var(--color7);
      background: rgb(255, 255, 255);
      .expansion-body {
        display: flex;
        flex-direction: column;
        padding: 2px 0;
        .selectable-items {
          padding: 2px 10px;
          margin: 2px 0;
          cursor: pointer;
          // background: red;
          &:hover {
            background: grey;
          }
          input {
            // margin-left: 10px;
          }
          input[type="radio"] {
            visibility: hidden;
          }

          display: flex;
          align-items: center;

          .item-holder {
            display: flex;
            align-items: center;
          }
        }
      }
      &.show {
        border: 2px solid var(--color7);
        border-top: none;
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      //scroolbar

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color3);
        border-radius: 30px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--color8);
      }
      //scroolbar
    }

    .error-message {
      min-height: 25px;
      padding-top: 2px;
      font-size: 14px;
      margin-left: 5px;
      color: red;
    }
  }
  .icon-image {
    width: 20px;
    margin-right: 10px;
  }
}
</style>
