<template>
  <BaseModal
    :dialog="show"
    @close="show = false"
    title="Latest News"
    class="news-modal"
  >
    <div>
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in newsData" :key="index">
          <h4 class="text-center news-title" v-if="item.title">{{ item.title }}</h4>
          <h6 class="text-center news-subtitle" v-if="item.short_description">
            {{ item.short_description }}
          </h6>
          <div class="content-holder">
            <div class="video-container" v-if="item.video">
              <video controls class="news-video">
                <source :src="newsStorage(item.video)" type="video/mp4" width="100%" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div class="image-container" v-if="item.image">
              <img :src="newsStorage(item.image)" :alt="item.title" />
            </div>

            <div class="body-container" v-html="item.body"></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import { mapActions } from "vuex";

export default {
  components: { BaseModal, swiper, swiperSlide },
  data() {
    return {
      show: false,
      newsData: [],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoHeight: true,
        spaceBetween: 30,
      },
    };
  },

  methods: {
    ...mapActions(["getNewsInfo"]),

    newsStorage(address) {
      const {origin} = window.location
      return `${origin}${address}`
    },

    async get_news_data() {
      await this.getNewsInfo().then((res) => {
        if (res) {
          if (res.length) {
            this.newsData = res;

            this.show = true;
          }
        }
      });
    },

    stopVideo() {
      const videos = document.querySelectorAll(".news-video")

      videos.forEach(video => {
        video.pause()
      });
    }
  },

  mounted() {
    this.get_news_data();
  },

  watch: {
    show() {
      if (!this.show) {
        this.stopVideo()
      }
    }
  },
};
</script>

<style lang="scss">
.news-modal {
  .modal-body {
    max-height: calc(100vh - 285px);
    overflow: auto;
    background: rgb(17, 22, 37);
    padding: 10px;
  }

  .news-title {
    text-align: center;
    margin-bottom: 10px;
    color: white;
  }

  .news-subtitle {
    text-align: center;
    margin-bottom: 15px;
    color: white;
  }

  .content-holder {
    display: flex;
    flex-flow: column;
    gap: 0.75rem;
  }

  .video-container {
    video {
      width: 100%;
      min-width: 100%;
    }
  }

  .image-container {
    img {
      width: 100%;
    }
  }

  .body-container {
    * {
      margin-bottom: 0.75rem;
      color: white;
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
}
</style>
