<template>
  <div class="slider">
    <figure>
      <div class="slide container" v-for="(item, i) in slides" :key="i">
        <section class="banners">
          <div class="caption">
            <h1>{{ item.name }}</h1>
            <p class="Q-font">{{ item.description }}</p>
            <NeonButton class="start-btn" @click="startNow(item)">Start Now</NeonButton>
          </div>
          <div class="images">
            <img :src="imageLink(item.image)" alt="" srcset="" class="img" />
            <!-- <img v-if="item" :src="item.image" alt="" srcset="" class="img" /> -->
          </div>
        </section>
      </div>
    </figure>
    <div class="actions" id="one-item-actions">
      <button class="btn prev-btn" @click="changePage('prev')">
        <i class="bx bxs-chevrons-left bx-fade-right"></i>
      </button>
      <ul class="points">
        <li
          class="point"
          v-for="(item, i) in slides"
          :key="i"
          :class="i === Math.abs(activeSlide) ? 'active' : ''"
        ></li>
      </ul>
      <button class="btn next-btn" @click="changePage('next')">
        <i class="bx bxs-chevrons-right bx-fade-left"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      finallSlides: [],
      slidesImages: [
        // {
        //   id: 1,
        //   name: "name 1",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(2).png",
        // },
        // {
        //   id: 2,
        //   name: "name 2",
        //   description:
        //     "This is a best game in the world adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(3).png",
        // },
        // {
        //   id: 3,
        //   name: "name 3",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(4).png",
        // },
        // {
        //   id: 4,
        //   name: "name 4",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(1).png",
        // },
        // {
        //   id: 1,
        //   name: "name 1",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(2).png",
        // },
        // {
        //   id: 3,
        //   name: "name 3",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(4).png",
        // },
        // {
        //   id: 2,
        //   name: "name 2",
        //   description:
        //     "This is a best game in the world adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(3).png",
        // },
        // {
        //   id: 4,
        //   name: "name 4",
        //   description:
        //     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam odit fugiat corrupti omnis iusto. Distinctio sit assumenda sapiente vero, voluptates aperiam explicabo iusto quasi neque facilis esse velit aspernatur quod?",
        //   image: "(1).png",
        // },
      ],
      activeSlide: 0,
      autoChangeInterval: null,
    };
  },
  computed: {
    ...mapGetters(["slides", "token"]),
    lastSlide() {
      return this.activeSlide > this.slides.length - 1;
    },
    firstSlide() {
      return this.activeSlide < 0;
    },
  },
  mounted() {
    // this.fetchGames();
    // this.handleLocalImages();
    this.slidesCssInitial();
    this.autoChangePage();
  },
  destroyed() {
    clearInterval(this.autoChangeInterval);
  },
  methods: {
    // ...mapActions(["getGames"]),
    // fetchGames() {
    // this.getGames();
    // },
    // handleLocalImages(){ //this methods just for handle local images
    //     this.finallSlides = this.slides.map((element, i) => {
    //       let localImage = this.slidesImages[i]
    //         ? this.slidesImages[i].image
    //         : null;
    //       if (localImage) return { ...element, localImage };
    //       else return element;
    //     });
    // },
    // imageLinkProducer(image) {
    //   return `${process.env.VUE_APP_ROOT_API}/${image}`;
    // },
    slidesCssInitial() {
      //set the number of slides to css variable
      document.documentElement.style.setProperty("--slides-number", this.slides.length);
    },
    changePage(direction) {
      clearInterval(this.autoChangeInterval);
      //get the previous active slide from css
      const root = document.documentElement;
      this.activeSlide = getComputedStyle(root).getPropertyValue("--active-slide");
      //if next go forward
      if (direction === "next") {
        this.activeSlide++;
        if (this.lastSlide) {
          //if we are in last slide go to first
          this.activeSlide = 0;
        }
      } else {
        //if !next go prev
        this.activeSlide--;
        if (this.firstSlide) {
          //if we are in first slide go to last
          this.activeSlide = this.slides.length - 1;
        }
      }
      //set new calculated active slide to css variable
      root.style.setProperty("--active-slide", this.activeSlide);
      this.autoChangePage();
    },
    autoChangePage() {
      this.autoChangeInterval = setInterval(() => {
        this.changePage("next");
      }, 6000);
    },
    // stopAndStartInterval() {
    //   //if we hoverd on action buttons our interval that change slides automaticly will be deactive and with mouseover event of buttons that be active again.
    //   const actions = document.querySelector("#one-item-actions");
    //   actions.addEventListener("mouseover", () => {
    //     clearInterval(this.autoChangeInterval);
    //   });
    //   actions.addEventListener("mouseout", () => {
    //     this.autoChangePage();
    //   });
    // },

    startNow(item) {
      this.$router.push({
        name: `${this.token ? "game" : "unauth-user-game"}`,
        params: { id: item.id },
      });
    },

    imageLink(image) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${image}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_sliders.scss";
@import "@/assets/style/_mixins.scss";
.slider {
  overflow: hidden;
  position: relative;
  min-height: 80vh;
  @include sm {
    min-height: unset;
  }
  figure {
    position: relative;
    height: inherit;
    margin: 0;
    width: calc(var(--slides-number) * 100%);
    right: calc(var(--active-slide) * 100%);
    display: flex;
    transition: right 1s ease;
    .slide {
      height: inherit;
      width: 100%;
      float: left;
      @include md {
        padding: 0;
      }
      .banners {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include md {
          position: relative;
        }
        .caption {
          width: 50%;
          padding: 100px 0;
          padding-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include sm {
            width: 100%;
            z-index: 2;
          }
          h1 {
            animation: moveDown 1s ease;
            font-size: 30px;
            text-transform: uppercase;
            color: white;
            @include md {
              font-size: 25px;
            }
          }
          p {
            animation: moveDown 1s ease forwards;
            animation-delay: 0.3s;
            opacity: 0;
            font-size: 18px;
            // font-style: italic;
            margin-top: 20px;
            color: var(--color6);
            @include md {
              font-size: 14px;
            }
            @include sm {
              font-size: 12px;
            }
          }
          .start-btn {
            animation: moveDown 1s ease forwards;
            animation-delay: 0.6s;
            opacity: 0;
            width: clamp(180px, 50%, 280px);
            margin-left: 10px;
            margin-top: 50px;
          }
        }
        .images {
          width: 50%;
          display: flex;
          justify-content: center;
          .img {
            width: 100%;
            max-width: 500px;
            min-height: 600px;
            max-height: 90vh;
            animation: moveDown 1s ease;
            @include sm {
              max-width: unset;
            }
          }
          @include sm {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .actions {
    position: absolute;
    bottom: 5%;
    right: 5%;
    display: flex;
    animation: moveDown 1s ease forwards;
    animation-delay: 0.9s;
    opacity: 0;
    z-index: 2;
    .btn {
      border: none;
      background: transparent;
      margin: 0 30px;
      cursor: pointer;
      i {
        font-size: 30px;
        color: var(--color5);
      }
    }
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
