import moment from "moment";
export const dateFormater = {
  methods: {
    dateFormater(input) {
      let date = new Date(input);
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // let days = [
      //   "Sunday",
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      // ];

      // let day = days[date.getDay()];
      let month = months[date.getMonth()];
      let year = date.getFullYear();

      return `${date.getDate()} ${month} ${year}`;
    },
  },
};

export const timeFormater = {
  methods: {
    timeFormater(input) {
      let date = new Date(input);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      return `${hours}:${minutes}`;
    },
  },
};

export const remainingTime = {
  data() {
    return {
      rTInterval: null,
      rTime: null,
      rTimeFlag: false,
    };
  },
  methods: {
    convertDate(date, format) {
      return moment.utc(date, "YYYY-MM-DD hh:mm:ss").local().format(format);
    },
    currentTimeStamp() {
      return moment.utc().unix();
    },
    dateTimeStamp(date) {
      return moment.utc(date).unix();
    },
    remainingTimeCalc(input) {
      let date2 = this.dateTimeStamp(input);
      let date = this.currentTimeStamp();

      let diff = date2 - date;

      let days = Math.floor(diff / 86400);
      let hours = Math.floor(diff / 3600) % 24;
      let minutes = Math.floor(diff / 60) % 60;
      let seconds = diff % 60;

      //

      let daysStr = days;
      if (days < 10) {
        daysStr = "0" + days;
      }

      let hoursStr = hours;
      if (hours < 10) {
        hoursStr = "0" + hours;
      }

      let minutesStr = minutes;
      if (minutes < 10) {
        minutesStr = "0" + minutes;
      }

      let secondsStr = seconds;
      if (seconds < 10) {
        secondsStr = "0" + seconds;
      }

      if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
        daysStr = "00";
        hoursStr = "00";
        minutesStr = "00";
        secondsStr = "00";

        if (typeof this.rTInterval !== "undefined") {
          clearInterval(this.rTInterval);
        }
      }

      if (diff <= 0) this.rTimeFlag = false;
      else this.rTimeFlag = true;

      this.rTime =
        daysStr + " Days , " + hoursStr + ":" + minutesStr + ":" + secondsStr;
    },
    remainingTime(input) {
      this.rTInterval = setInterval(() => {
        this.remainingTimeCalc(input);
      }, 1000);
    },
  },
};
