<template>
  <div class="button" @click="$emit('click')"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 2px;
  padding: 8px 30px;
  cursor: pointer;
  @include sm {
    font-size: 10px;
    padding: 8px 10px;
  }
  &::after {
    content: "";
    box-shadow: 0 0 2px var(--color1);
    background: linear-gradient(90deg, var(--color1), var(--color2), var(--color1));
    width: 106%;
    height: 110%;
    border-radius: 5px;
    position: absolute;
    background-size: 300% 300%;
    background-position: 0 50%;
    z-index: -1;
    animation: moveGradient 6s cubic-bezier(0.34, 0.48, 0.27, 0.6) alternate infinite;
  }

  &:hover {
    // background: linear-gradient(
    //   35deg,
    //   var(--color2),
    //   var(--color1),
    //   var(--color2)
    // );
    color: var(--color4);
    &::after {
      box-shadow: 0 0 20px var(--color2);
      transition: all 1s ease;
      // animation-play-state: paused;
    }
  }
}
@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
    box-shadow: 0 0 3px var(--color2);
  }
}
</style>
